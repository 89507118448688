import styles from "styles/common/filters.module.scss";

interface Props {
    hideCanvas?: () => void,
    showCanvas: () => void,
}

const CanvasButton = ( { showCanvas }: Props) => {
    return (
        <button className={styles.canvasButton} type="button" onClick={showCanvas}>
            <svg width='19' height='19' fill="white">
                <use xlinkHref='/theme/font-awesome/solid.svg#magnifying-glass'></use>
            </svg>
        </button>
    )
}

export default CanvasButton