import FileDto from "services/api/dto/FileDto";

export const emptyNewsDto: ArticleDto = {
    uid: "",
    slug: "",
    lead: "", 
    content: "",
    lang: "",
    credit: "",
    author_initials: "",
    publish_at: "",
    news_categories_codes: [],
    news_category_code: "",
    embargo: false,
    embargo_until: ""
}

export function isNewsDto(object: any): object is ArticleDto{
    return true;
} 

export default interface ArticleDto {
    uid: string,
    publish_at: string,
    slug: string,
    lead: string, 
    content?: string,
    lang?: string,
    credit?: string,
    author_initials?: string,
    news_categories_codes: [],
    news_category_code?: string,
    embargo?: boolean,
    embargo_until?: string,
    files?: FileDto[],
}