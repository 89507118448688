import styles from 'styles/common/scroller.module.scss'

interface Props {
    targetClass: string
}

const Scroller = ({targetClass}: Props) => {

    const onLeftClick = () => {
        const scrollWindow = document.getElementsByClassName(targetClass)
        if(scrollWindow && scrollWindow.length > 0){
            scrollWindow[0].scrollBy({left: -200, behavior: "smooth"})
        }
    }

    const onRightClick = () => {
        const scrollWindow = document.getElementsByClassName(targetClass)
        if(scrollWindow && scrollWindow.length > 0){
            scrollWindow[0].scrollBy({left: 200, behavior: "smooth"})
        }
    }
    
    return(
        <div className={styles.container}>
            <div className={styles.scrollLeft} onClick={() => onLeftClick()}>
                <svg className={styles.icon} width='24' height='24' fill='black'>
                    <use xlinkHref='/theme/font-awesome/solid.svg#angle-left'></use>
                </svg>
            </div>
            <div className={styles.scrollRight} onClick={() => onRightClick()}>
                <svg className={styles.icon} width='24' height='24' fill='black'>
                    <use xlinkHref='/theme/font-awesome/solid.svg#angle-right'></use>
                </svg>
            </div>
        </div>
    )
}

export default Scroller