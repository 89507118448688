import React, { createContext, useState, useContext, useEffect } from "react";

export const PAGE_TITLE_DEFAULT = 'Tanjug - pretplatnički servis';

export interface PageMeta {
    title?: string,
    description?: string,
}

interface PageMetaContextProps {
    pageMeta?: PageMeta | null,
    setPageMeta: React.Dispatch<React.SetStateAction<PageMeta>> | any,
}

const initialCtx = {
    pageMeta: {title: PAGE_TITLE_DEFAULT},
    setPageMeta: (pageMeta: React.SetStateAction<PageMeta>) => pageMeta
}

export const PageMetaContext = createContext<PageMetaContextProps>(initialCtx);

interface Props {
    children: any
}

const PageMetaContextProvider = ({ children }: Props) => {
    const [pageMeta, setPageMeta] = useState<PageMeta>({
        title: PAGE_TITLE_DEFAULT
    });

    useEffect(() => {
        const {title, description} = pageMeta
        const oldTitle = document.title;

        if (title) {
            document.title = title
        } else {
            document.title = PAGE_TITLE_DEFAULT
        }

    }, [pageMeta]);

    return (
        <PageMetaContext.Provider value={{ pageMeta, setPageMeta }}>
            {children}
        </PageMetaContext.Provider>
    )
}

export const usePageMeta = () => {
    return useContext(PageMetaContext);
};

export default PageMetaContextProvider