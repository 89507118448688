import { formatDateTimeToHumanFromString } from "helpers/formatDateTools";
import styles from "styles/photo/photo-item-view.module.scss";
import { useApiClient } from "contexts/ApiClientContext";
import PhotoDto from "services/api/dto/photos/PhotoDto";
import ScrollContainer from 'react-indiana-drag-scroll';
import { useTranslation } from "react-i18next";
import { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { saveAs } from 'file-saver';
import Scroller from "components/layout/Scroller";


interface Props {
    photo: PhotoDto,
    relatedPhotos: PhotoDto[] | undefined
}

const PhotoItemView = ({ photo, relatedPhotos }: Props) => {
    const { t } = useTranslation();
    const apiClient = useApiClient();
    const vieWRef = useRef<HTMLDivElement>(null);
    
    const downloadPhoto = async () => {
        try {
            const response = await apiClient.downloadPhoto({uid: photo.uid, label: 'orig'});
            if(response && response?.data?.url){
                const downloadLink: HTMLAnchorElement = document.createElement('a');
                document.body.append(downloadLink);
                downloadLink.href = response.data.url;
                downloadLink.download = photo.orig_file_name ?? 'photo_tanjug';
                downloadLink.text = "Fotografija - (" + photo.credit + ") - " + (photo.headline ? photo.headline + " - " : "") + photo.title;
                downloadLink.click();
                downloadLink.remove();
                toast.success(`${t("download_started_single")}`, {autoClose: 1000, position: "top-left"})
            } else {
                toast.error(t('server_error'));
            }
        } catch (errors: any) {
            switch (errors.response.status) {
                case 403:
                    toast.error(t('constrained_item'));
                    break;
                default:
                    break;
            }
        }
    }

    useEffect(() => {
        vieWRef.current?.scrollTo({
            top: 0,
            behavior: 'smooth',
          });
    }, [photo]);

    return (
        <>
            <div className={styles.header}>
                <div className="d-flex justify-content-center">
                    <span>{photo?.publish_at ? formatDateTimeToHumanFromString(photo?.publish_at) : photo?.publish_at}</span>
                </div>
                <div>
                    <a onClick={ async () => await downloadPhoto() }>
                        <svg width='15' height='15'>
                            <use xlinkHref='/theme/font-awesome/solid.svg#download'></use>
                        </svg>
                        {t("download")} {Math.round((photo.files[0].size / 1024 / 1024) * 100) / 100}MB
                    </a>
                </div>
            </div>
            <div 
                className={styles.content}
                ref={vieWRef}
                >
                <div className={styles.contentTop}>
                    <figure className="img-placeholder">
                        <img src={ photo.files.find((item) => item.label == 'watermark')?.url } alt={ photo.files.find((item) => item.label == 'watermark')?.name } />
                    </figure>
                    <h4>{photo?.headline}</h4>
                    <h2>{photo?.title}</h2>
                </div>
                {relatedPhotos?.length !== undefined && relatedPhotos.length > 1
                ?
                <div className={styles.contentSlider}>
                    {relatedPhotos.length > 3 && document.body.clientWidth > 786
                    ? 
                    <Scroller targetClass={styles.sliderWrapper}/> 
                    : 
                    ''}
                    <p>{t("related_photos")} ({ relatedPhotos?.length ?? 0 })</p>
                    
                    <ScrollContainer className={styles.sliderWrapper}>
                        { relatedPhotos?.map((singlePhoto) => 
                            <Link key={singlePhoto?.uid} to={`/photos/${singlePhoto.uid}`} className={styles.sliderItem}>
                                <div key={singlePhoto?.uid}>
                                    <figure key={singlePhoto?.uid}>
                                        <img key={singlePhoto?.uid} src={ singlePhoto.files.find((item) => item.label == 'thumb')?.url } alt={singlePhoto.orig_file_name} />
                                    </figure>
                                    <p>{singlePhoto?.title}</p>
                                </div>
                            </Link>
                        ) }
                    </ScrollContainer>
                </div>
                :
                ""
                }
                <div className={styles.contentMiddle}>
                    {photo.description}
                </div>
                <div className={styles.contentBottom}>
                    <div className="d-flex justify-content-between">
                        <span>{t('category')}:</span>
                        <span>{photo?.photo_category_code}</span>
                    </div>
                    <div className="d-flex justify-content-between">
                        <span>{t('credit')}:</span>
                        <span>{photo?.credit}</span>
                    </div>
                    <div className="d-flex justify-content-between">
                        <span>{t('language')}:</span>
                        <span>{photo?.lang}</span>
                    </div>
                    <div className="d-flex justify-content-between">
                        <span>{t('photographer')}:</span>
                        {photo?.photographer !== "null" 
                        ? 
                        <span>{photo?.photographer + (photo.photographer_initials !== "" ? " (" + photo.photographer_initials + ")" : "") }</span>
                        : 
                        ""
                        }
                    </div>
                    <div className="d-flex justify-content-between">
                        <span>{t('source')}:</span>
                        <span>{photo?.source}</span>
                    </div>
                    <div className="d-flex justify-content-between">
                        <span>{t('geo_location')}:</span>
                        <span>{photo?.geo_location}</span>
                    </div>
                    <div className="d-flex justify-content-between">
                        <span>{t('copyright')}:</span>
                        <span>{photo?.copyright}</span>
                    </div>
                    <div className="d-flex justify-content-between">
                        <span>{t('categories')}:</span>
                        <span>{ (photo?.photo_categories_codes ?? []).join(' - ') }</span>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PhotoItemView