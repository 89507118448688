import PhotosSearchRequestDto from "services/api/dto/photos/PhotosSearchRequestDto";
import React, { useState, useEffect, Dispatch, SetStateAction } from "react";
import forbiddenStyles from 'styles/photo/photo-forbidden.module.scss';
import styleList from 'styles/photo/list-layout.module.scss';
import styles from 'styles/photo/grid-layout.module.scss';
import NewsSkeleton from "components/loaders/NewsLoader";
import LoaderIcon from "components/loaders/NewsSkeleton";
import { useApiClient } from "contexts/ApiClientContext";
import { useInView } from "react-intersection-observer";
import PhotoDto from "services/api/dto/photos/PhotoDto";
import PhotoItemGrid from "components/photo/PhotoItemGrid";
import PhotoItemList from "components/photo/PhotoItemList";
import { Outlet, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useInfiniteQuery } from "react-query";

interface Props {
    searchFilters: PhotosSearchRequestDto,
    selectedPhotos: PhotoDto[],
    photoSelectDispatch: boolean,
    photoDeselectDispatch: boolean,
    imagesDisplayMode: boolean,
    onSelectAllPhotos: (photos: PhotoDto[]) => void,
    onDeselectAllPhotos: () => void,
    onSelectPhoto: (photo: PhotoDto) => void,
    setResultsNumber: Dispatch<SetStateAction<number>> 
}

const Photo = ({ searchFilters, photoSelectDispatch, photoDeselectDispatch, selectedPhotos, imagesDisplayMode, onSelectAllPhotos, onDeselectAllPhotos, onSelectPhoto, setResultsNumber }: Props) => {
    const { uid } = useParams();
    const { t } = useTranslation();
    const { ref, inView } = useInView();
    const [showSideBar, setShowSideBar] = useState<boolean>(uid ? true : false);
    const apiClient = useApiClient();
    const {
        isLoading,
        data,
        error,
        isFetchingNextPage,
        fetchNextPage,
        hasNextPage,
    } = useInfiniteQuery(['photos', searchFilters], ({ pageParam }) => apiClient.photos(pageParam, searchFilters), {
        retry: false,
        getNextPageParam: lastPage => lastPage.meta?.next_page !== undefined && lastPage.meta.next_page > 0 ? lastPage.meta.next_page : false
    });

    useEffect(() => {
        if (!uid) {
            setShowSideBar(false);
        }
        if (inView && hasNextPage) {
            fetchNextPage();
        }
    }, [uid, inView]);

    useEffect(() => {
        if(photoSelectDispatch){
            let photos: PhotoDto[] = []
            for(const page of data?.pages ?? []){
                if(page.data){
                    photos = photos.concat(page.data?.map((photo) => photo))
                }
            }
            onSelectAllPhotos(photos);
        }

        if(photoDeselectDispatch) {
            let photos: PhotoDto[] = []
            for(const page of data?.pages ?? []){
                if(page.data){
                    photos = photos.concat(page.data.map((photo) => photo))
                }
            }
            onDeselectAllPhotos();
        }
    }, [photoSelectDispatch, photoDeselectDispatch]);

    useEffect(() => {
        setResultsNumber(data?.pages[0].meta?.total_rows ?? 0)
        if (inView && hasNextPage) {
            fetchNextPage();
        }
    }, [data]);

    const handleShowSideBar = (): void => {
        if (uid) {
            setShowSideBar(true);
        } else {
            setShowSideBar(!showSideBar)
        }
    };

    return (
        <>
            <section className={`${imagesDisplayMode ? styleList.layout : styles.layout} ${styles.singlePhoto}`}>
                <div className={imagesDisplayMode ? styleList.container : styles.container}>
                    <div className={imagesDisplayMode ? styleList.wrapper : styles.wrapper}>
                        <div className={imagesDisplayMode ? ` ${showSideBar ? styleList.itemWrapperActive : styleList.itemWrapper}` : ` ${showSideBar ? styles.itemWrapperActive : styles.itemWrapper}`}>
                            {
                                isLoading ? <NewsSkeleton /> :
                                    data?.pages.map((page) =>
                                        <React.Fragment key={page.meta?.next_page}>
                                            <>
                                                {page.data?.map((photoItem) =>
                                                    imagesDisplayMode ?
                                                    <PhotoItemList 
                                                        key={photoItem.uid} 
                                                        photo={photoItem} 
                                                        handleShowSideBar={handleShowSideBar}
                                                        onSelectPhoto={onSelectPhoto}
                                                        checked={
                                                            selectedPhotos.find(item => item.uid === photoItem.uid) !== undefined ?
                                                            true :
                                                            false
                                                        }
                                                    /> :
                                                    <PhotoItemGrid
                                                        key={photoItem.uid} 
                                                        photo={photoItem} 
                                                        handleShowSideBar={handleShowSideBar}
                                                        onSelectPhoto={onSelectPhoto}
                                                        checked={
                                                            selectedPhotos.find(item => item.uid === photoItem.uid) !== undefined ?
                                                            true :
                                                            false
                                                        }
                                                    />
                                                )}
                                            </>
                                        </React.Fragment>
                                    )
                            }
                        </div>
                        {showSideBar ? <Outlet /> : null}
                    </div>
                    {document.body.clientWidth < 768 && uid 
                    ?
                    ''
                    :
                    <div ref={ref} className="loader">
                        {isFetchingNextPage ? <LoaderIcon /> : (uid ? '' : <span>{t('no_more_results')}</span>)}
                    </div>
                    }
                </div>
                {
                    error !== undefined && error ? 
                    <div className={forbiddenStyles.container}>
                        <h1 className={forbiddenStyles.text}>
                            {t('not_subscribe_message')}
                        </h1>
                    </div>
                    :
                    null
                }
            </section>
        </>
    )
}

export default Photo;