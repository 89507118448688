import 'styles/login/login.scss'
import styles from 'styles/login/login.module.scss'
import { useEffect, useState } from "react"
import { useApiClient } from "contexts/ApiClientContext";
import { useAuth } from "contexts/AuthContext"
import { Navigate, useLocation } from "react-router-dom";
import { AxiosError } from 'axios';
import { toast } from 'react-toastify';
import { usePageMeta, PAGE_TITLE_DEFAULT } from 'contexts/PageMetaContext';
import ReactGA from "react-ga4";


const Login = () => {
    const { auth, setAuth } = useAuth();
    const apiClient = useApiClient();
    const location = useLocation();

    const { setPageMeta } = usePageMeta();

    useEffect(() => {
        setPageMeta({title: PAGE_TITLE_DEFAULT + " - Login" })
    }, [])

    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')

    const handleSubmit = async (e: any) => {
        e.preventDefault();

        const response = await apiClient.authLogin({username, password});

        if(response instanceof AxiosError || response === null){
            if(response?.response?.status === 400){
                toast.error(<>Pogrešno korisničko ime ili lozinka!<br/>Wrong username or password!</>)
            } else if(response?.response?.status === 401) {
                toast.error(<>Pogrešno korisničko ime ili lozinka!<br/>Wrong username or password!</>)
            } else if(response === null) {
                toast.error(<>Pogrešno korisničko ime ili lozinka!<br/>Wrong username or password!</>)
            } else {
                toast.error(<>Došlo je do greške na serveru. <br/>A server error has occured.</>)
            }
        } else if(response) {
            localStorage.setItem('access_token', JSON.stringify(response?.data?.access_token))
            localStorage.setItem('user', JSON.stringify(response?.data?.user))
            localStorage.setItem('account', JSON.stringify(response?.data?.account))

            setAuth({ accessToken: response?.data?.access_token, user: response?.data?.user, account: response?.data?.account })

            ReactGA.event('login');
            
            window.location.href = '/'
        }
    }

    return (
        <>
            {auth?.accessToken?.access_token
                ? <Navigate to={'/'} state={{ from: location }} replace />
                :
                <>
                    <main className={styles.main}>

                    <section className="login">
                        <div className="login-wrapper">
                            <div className="login-form-wrapper">
                                <div className="logo-title-wrapper">
                                    <img className='logo' src="/theme/img/logo.png" />
                                    <h2>Korisnik</h2>
                                </div>
                                <form action="" className="login-form">
                                    <div className="form-group">
                                        <label htmlFor="email">Korisničko ime / Username</label>
                                        <input name="username"
                                            onChange={e => setUsername(e.target.value)}
                                            value={username}
                                            className="form-control"
                                            placeholder=""
                                            required/>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="password">Lozinka / Password</label>
                                        <input type="password" name="password"
                                            onChange={e => setPassword(e.target.value)}
                                            value={password}
                                            className="form-control"
                                            placeholder=""/>
                                        <div className="error-msg"></div>
                                    </div>
                                    <div className="btn-login-wrapper">
                                        <button type="submit" onClick={handleSubmit} className="btn-login">Prijava</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="login-data">
                            <div className="login-data-wrapper">
                                <a href="/" className="login-logo">
                                    <img src="/theme/img/logo.png" alt=""/>
                                </a>
                                <div className="login-info">
                                    <h2>Tanjug<br/>pretplatnički servis</h2>
                                    <p>Želite da ostavite komentar, čitate vesti u
                                        potpunosti,
                                        pristup bogatoj arhivi
                                    </p>
                                    <div className="login-numbers">
                                        <div className="login-numbers-item">
                                            <p>600.000+</p>
                                            <p>Vesti</p>
                                        </div>
                                        <div className="login-numbers-item">
                                            <p>500.000+</p>
                                            <p>Slika</p>
                                        </div>
                                        <div className="login-numbers-item">
                                            <p>300.000+</p>
                                            <p>Video</p>
                                        </div>
                                        <div className="login-numbers-item">
                                            <p>100.000+</p>
                                            <p>Fono zapisa</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
        
                </main>
        
                <footer>
                    <div className="container">
                        <p className="footer-info">Sva prava zadržana - <a href="index.html">Tanjug</a>© 2015 :: Obilićev venac 2, 11000 Beograd :: tel: <a href="tel:+381 11 3288 284">+381 11 3288 284</a>, <a href="tel:11 3288 285">11 3288 285</a>, <a href="tel:113288 286">113288 286</a>;email: <a href="mailto:office@tanjug.rs">office@tanjug.rs</a>site: <a href="index.html">www.tanjug.rs</a></p></div>
                </footer>
            </>
            }

        </>
    )
}

export default Login