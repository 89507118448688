import { formatDateTimeToHumanFromString } from "helpers/formatDateTools";
import { useApiClient } from "contexts/ApiClientContext";
import NewsDto from "services/api/dto/news/ArticleDto";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { saveAs } from 'file-saver';

interface Props {
  news?: NewsDto
}

const NewsItemView = ({ news }: Props) => {
  const { t } = useTranslation();
  const apiClient = useApiClient();
  
  const hasIconComponent = (type: string, text: string) => {
    switch (type) {
      case 'foto':
        return !!(text.match(/\(.*(foto|photo).*\)$/gmi) ?? []).length;
      case 'video':
        return !!(text.match(/\(.*video.*\)$/gmi) ?? []).length;
      case 'fono':
        return !!(text.match(/\(.*(fono|audio).*\)$/gmi) ?? []).length;
      default:
        return false;
    }
  };

  const downloadText = async () => {
    try {
        const response = await apiClient.downloadNews({uid: news?.uid ?? "", label: 'orig'});
        if(response && response?.data?.url){
            saveAs(response?.data?.url ?? "", news?.slug + ".txt" ?? "download_file.txt");
            toast.success(t('download_started'), {pauseOnHover: false, hideProgressBar: true, autoClose: 2000});
        } else {
            toast.error(t('server_error'));
        }
    } catch (errors: any) {
        switch (errors.response.status) {
            case 403:
                toast.error(t('constrained_item'));
                break;
            default:
                break;
        }
    }
  };

  const copyToClipBoard = (text: string) => {
    let success: boolean|Promise<void> = false;
    if (navigator.clipboard && window.isSecureContext) {
      success = navigator.clipboard.writeText(text);
    } else {
      const textField = document.createElement('textarea');
      textField.innerText = text;
      document.body.appendChild(textField);
      textField.select();
      success = document.execCommand('copy');
      textField.remove();
    }
    success ? toast.success(t('copied')) : toast.error(t('copy_error'));
  };
  
  return (
    <>
      <div className="grid-sidebar-header">
        <div className="d-flex justify-content-center">
          <span>{news?.publish_at ? formatDateTimeToHumanFromString(news?.publish_at) : news?.publish_at}</span>
        </div>
        <div className="d-flex justify-content-center icons-bar">
          { 
          (hasIconComponent('foto', news?.slug ?? "") || hasIconComponent('video', news?.slug ?? "") || hasIconComponent('fono', news?.slug ?? "")) ?
          <a className="d-flex justify-content-around">
            {hasIconComponent('foto', news?.slug ?? "") ? <svg className="m-2" width="17" height="17" fill="#254594">
              <use xlinkHref="/theme/font-awesome/solid.svg#camera"></use>
            </svg> : null}
            {hasIconComponent('video', news?.slug ?? "") ? <svg className="m-2" width="17" height="17" fill="#191c38">
              <use xlinkHref="/theme/font-awesome/solid.svg#video"></use>
            </svg> : null}
            {hasIconComponent('fono', news?.slug ?? "") ? <svg className="m-2" width="17" height="17" fill="#da251d">
              <use xlinkHref="/theme/font-awesome/solid.svg#volume-high"></use>
            </svg> : null}
          </a> : null 
          }
          <a href="#" onClick={ async () => await downloadText() }>
            <svg width="15" height="15">
              <use xlinkHref="/theme/font-awesome/solid.svg#download"></use>
            </svg>
            {t('download')} TXT
          </a>
        </div>
      </div>
      <div className="grid-sidebar-content">
        <div className="grid-sidebar-content-top">
          <h4>{news?.news_category_code + ":" + news?.slug}</h4>
          <h2>{news?.lead}</h2>
        </div>
        <div className="grid-sidebar-content-middle">
          <a href="#" onClick={() => copyToClipBoard(news?.content ?? "")}>
            <svg width="13" height="13">
              <use xlinkHref="/theme/font-awesome/solid.svg#copy"></use>
            </svg>
            {t('copy')}
          </a>
          <p>
            {news?.content}
          </p>
        </div>
        <div className="grid-sidebar-content-bottom">
        <div className="d-flex justify-content-between">
              <span>{t('categories')}:</span>
              <span>{ (news?.news_categories_codes ?? []).join(' - ') }</span>
          </div>
          <div className="d-flex justify-content-between">
              <span>{t('language')}:</span>
              <span>{news?.lang}</span>
          </div>
        </div>
      </div>
    </>
  );
}

export default NewsItemView