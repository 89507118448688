import PhotosSearchRequestDto from "services/api/dto/photos/PhotosSearchRequestDto";
import { createContext, useCallback, useEffect, useState } from "react";
import DownloadFileDto from "services/api/dto/DownloadFileDto";
import { useApiClient } from "contexts/ApiClientContext";
import PhotoDto from "services/api/dto/photos/PhotoDto";
import PhotoSearch from "components/photo/PhotoSearch";
import downloadAll from "helpers/downloadTools";
import { useTranslation } from "react-i18next";
import PhotoCanvas from "./PhotoCanvas";
import { toast } from "react-toastify";
import Photo from "pages/Photo";
import CanvasButton from "components/layout/CanvasButton";
import { Link } from "react-router-dom";
import { usePageMeta } from "contexts/PageMetaContext";

export const DisplayFilterContext = createContext<{ yOffset: number, show: boolean }>({ yOffset: 0, show: true });

const PhotoWrapper = () => {

    const searchHistory = localStorage.getItem('photoPage');
    const [search, setSearch] = useState<PhotosSearchRequestDto>(JSON.parse(searchHistory ?? "{}") ?? {search: ''});
    const [resultsNumber, setResultsNumber] = useState<number>(0);
    const [selectedPhotos, setSelectedPhotos] = useState<PhotoDto[]>([]);
    const [photoSelectDispatch, setPhotoSelectDispatch] = useState<boolean>(false);
    const [imagesDisplayMode, setImagesDisplayMode] = useState<boolean>(window.innerWidth > 992 ? true : false);
    const [photoDeselectDispatch, setPhotoDeselectDispatch] = useState<boolean>(false);
    const [displayFilters, setDisplayFilters] = useState<{ yOffset: number, show: boolean }>({ yOffset: 0, show: true });
    const [displayCanvas, setDisplayCanvas] = useState<boolean>(window.innerWidth > 992 ? false : true);
    const apiClient = useApiClient();
    const { t } = useTranslation();

    const { setPageMeta } = usePageMeta();

    useEffect(() => {
        setPageMeta({ title: "Fotografije"})
    }, [])

    const handleSearchSubmit = (searchObject: PhotosSearchRequestDto) => {
        setSearch(searchObject);
    }

    const handleSelectPhoto = (photo: PhotoDto) => {
        if(selectedPhotos.find(item => item.uid == photo.uid) !== undefined){
            setSelectedPhotos(selectedPhotos.filter(item => item.uid !== photo.uid));
        } else {
            setSelectedPhotos([...selectedPhotos, photo]);
        }
    }

    const handleSelectAllPhotos = (photoUids: PhotoDto[]) => {
        setSelectedPhotos(photoUids);
        setPhotoSelectDispatch(false);
    }

    const handleDeselectAllPhotos = () => {
        setSelectedPhotos([]);
        setPhotoDeselectDispatch(false);
    }

    const handleDownloadAll = async () => {
        const downloadableUrls: DownloadFileDto[] | any = []

        const response = await Promise.all(selectedPhotos.map(photo => apiClient.downloadPhoto({uid: photo.uid, label: 'orig'}))).then(
            data => data.map(urls => downloadableUrls.push(urls.data))
        ).catch(() => {
            toast.error(t('server_error'));
        })
        if(response && downloadableUrls.length) {
            downloadAll(downloadableUrls, {start: t("preparing_download"), update: t("download_started")}, selectedPhotos);
        }
        setSelectedPhotos([]);
    }

    const handleScroll = useCallback(() => {
        if (displayFilters.yOffset > window.scrollY && window.innerWidth > 992) {
            setDisplayFilters({ show: true, yOffset: window.scrollY });
        } else {
            setDisplayFilters({ show: false, yOffset: window.scrollY });
        }
    }, [displayFilters]);

    const handleFilters = useCallback(() => {
        if (window.innerWidth > 992) {
            setDisplayCanvas(false);
            setDisplayFilters({ show: true, yOffset: window.scrollY });
            setImagesDisplayMode(true);
        } else {
            setDisplayFilters({ show: false, yOffset: window.scrollY });
            setDisplayCanvas(true);
            setImagesDisplayMode(false);
        }
    }, [displayCanvas]);

    const dispatchSelectAll = (select = true) => {
        setPhotoSelectDispatch(select);
    }

    const dispatchDeselectAll = (select = true) => {
        setPhotoDeselectDispatch(select);
    }

    const showCanvas = () => {
        const canvas = document.getElementById("canvas");
        if(canvas != null) {
            canvas.style.width = "100%";
        }  
        // const layout = document.getElementsByClassName('grid-layout-video');
        // if(layout[0] instanceof HTMLDivElement) {
        //     layout[0].style.backgroundColor = "rgba(0,0,0,0.4)";
        // }
    }
  
    const hideCanvas = () => {
        const canvas = document.getElementById("canvas");
        if(canvas != null) {
            canvas.style.width = "0";
        }  
    }

    const handleImagesDisplayMode = (mode:boolean): void => {
        setImagesDisplayMode(!mode)
    }

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        }
    }, [handleScroll]);

    useEffect(() => {
        window.addEventListener('resize', handleFilters);
    }, [handleFilters]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [search]);
    
    return(
        <main>
            <DisplayFilterContext.Provider value={displayFilters}>
                { displayCanvas ? <PhotoCanvas results={resultsNumber} onSearchSubmit={handleSearchSubmit} hideCanvas={hideCanvas} /> : null }
                {
                !displayCanvas ? 
                    <PhotoSearch 
                        results={resultsNumber}
                        selectedPhotos={selectedPhotos}
                        imagesDisplayMode={imagesDisplayMode}
                        onSearchSubmit={handleSearchSubmit} 
                        dispatchSelectAll={dispatchSelectAll}
                        dispatchDeselectAll={dispatchDeselectAll}
                        handleDownloadAll={handleDownloadAll}
                        handleImagesDisplayMode={handleImagesDisplayMode}
                    /> : <CanvasButton showCanvas={showCanvas} />
                }
                <Photo
                    searchFilters={search}
                    selectedPhotos={selectedPhotos}
                    photoSelectDispatch={photoSelectDispatch}
                    photoDeselectDispatch={photoDeselectDispatch}
                    imagesDisplayMode={imagesDisplayMode}
                    onSelectPhoto={handleSelectPhoto} 
                    setResultsNumber={setResultsNumber}
                    onSelectAllPhotos={handleSelectAllPhotos}
                    onDeselectAllPhotos={handleDeselectAllPhotos}
                />
            </DisplayFilterContext.Provider>
        </main>
    )
}

export default PhotoWrapper