import NewsWIdgetSkeleton from "components/loaders/NewsWIdgetSkeleton";
import { useNavigate, useParams } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { DisplayFilterContext } from "components/fono/FonoWrapper";
import FonoItemView from "components/fono/FonoItemView";
import { useQuery } from "react-query";
import { useApiClient } from "contexts/ApiClientContext";
import FonoDto, {emptyFonoDto, isFonoDto} from "services/api/dto/fono/FonoDto";
import { usePageMeta } from "contexts/PageMetaContext";

const FonoItemWidget = () => {
  const { uid } = useParams();
  const navigate = useNavigate();
  const apiClient = useApiClient();
  const displayFilters = useContext(DisplayFilterContext);
  const { isLoading, error, data } = useQuery([uid], () => apiClient.fonoItem(uid), { retry: false });
  const [fetchedItem, setFetchedItem] = useState<FonoDto>(emptyFonoDto);

  const { setPageMeta } = usePageMeta();

  useEffect(() => {
    if(!isLoading) {
      if (error) {
        console.log('Greska');
      } else if(data?.data && isFonoDto(data?.data)) {
        setFetchedItem(data?.data);

        setPageMeta({title: "Fono - " + data?.data.title});
      }
    }
  }, [isLoading, error, data]);

  return (
    <div className={!displayFilters.show && displayFilters.yOffset > 0 ? 'grid-scroll-wrapper' : 'grid-sidebar-wrapper' } >
      <div className='grid-sidebar-main-wrapper'>
        <span className="close"onClick={() => navigate(`/fono`)}>
          <svg width='17' height='17'>
            <use xlinkHref='/theme/font-awesome/solid.svg#x'></use>
          </svg>
        </span>

        {!isLoading && isFonoDto(fetchedItem) ? <FonoItemView fono={fetchedItem} /> : <NewsWIdgetSkeleton />}

      </div>
    </div>
  );
}

export default FonoItemWidget