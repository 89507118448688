import { axiosPublic } from "services/api/axios";
import ApiResponseDto from "services/api/dto/ApiResponseDto";
import AccessTokenDto from "services/api/dto/auth/AccessTokenDto";
import { AuthObject } from "contexts/AuthContext";
import axios, { AxiosError, AxiosResponse } from "axios";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

const useRefreshToken = () => {
    const { t } = useTranslation();
    const refresh = async (auth: AuthObject) => {
        
        const response: AxiosResponse<ApiResponseDto<{ access_token: AccessTokenDto }, undefined>> | false | undefined = 
            await axiosPublic.post<ApiResponseDto<{access_token: AccessTokenDto}, undefined>>('/auth/refresh-token',
                JSON.stringify(auth)).catch((err: AxiosError) => {
                    if(axios.isAxiosError(err)){
                        if(err?.response?.status === 401){
                            toast.error(t("session_expired"));
                            return false;
                        }
                    } else {
                        toast.error(t("session_expired")); 
                        return false;
                    }
                })

        if(response === undefined) {
            return false;
        } else if (response !== false && response.data) {
            localStorage.setItem('access_token', JSON.stringify(response?.data));
            return response?.data;
        }
    }
    
    return refresh
}

export default useRefreshToken