import VideoGalleriesSearchRequestDto from "services/api/dto/videos/VideoGalleriesSearchRequestDto";
import { useContext, useEffect, useReducer, useRef, useState } from "react";
import { formatDateToHumanFromString, formatDateToIsoString } from "helpers/formatDateTools";
import { useApiClient } from "contexts/ApiClientContext";
import styles from "styles/common/filters.module.scss";
import { DisplayFilterContext } from "./VideoWrapper";
import { DateRangePicker, RangeKeyDict } from "react-date-range";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import Select from 'react-select';
import { parse } from "date-fns";
import { getTotalRanges } from "helpers/filterHelpers";
import PhotoCategoryDto from "services/api/dto/photos/PhotoCategoryDto";

const initializedDate = formatDateToIsoString(new Date());

const initialState: InternalState = {
    search: "",
    categoryOptions: [],
    selectedCategories: [],
    selectedCategory: undefined,
    publish_at_from: null,
    publish_at_to: null,
    displayRefreshButton: false,
    displayResetButton: false,
    displayCalendars: false,
}

type FormAction =
    { type: "setSearchTerm", payload: string }
    | { type: "setSelectedCategories", payload: Option[] }
    | { type: "setCategoryOptions", payload: Option[] }
    | { type: "setSelectedCategory", payload: Option }
    | { type: "setCalendarValue", payload: {publish_at_from : string, publish_at_to: string } }
    | { type: "displayCalendars", payload: boolean }
    | { type: "displayRefreshButton" | "displayResetButton", payload: boolean }
    | { type: 'setFiltersByHistory', payload: InternalState }
    | { type: 'setInitialState', payload: InternalState }

interface Option {
    value: string,
    label: string,
}
interface InternalState extends VideoGalleriesSearchRequestDto {
    categoryOptions: Option[],
    selectedCategories: Option[],
    selectedCategory?: Option,
    displayRefreshButton: boolean,
    displayResetButton: boolean,
    displayCalendars: boolean,
}

interface Props {
    results: number,
    onSearchSubmit: (search: VideoGalleriesSearchRequestDto) => void
}

function reducer(state: InternalState, action: FormAction): InternalState {
    switch (action.type) {
        case 'setSearchTerm':
            return {
                ...state,
                'search': action.payload,
                'displayRefreshButton': true
            };
        case 'setSelectedCategories':
            return {
                ...state,
                'categories': action.payload.map((item) => item.value),
                'selectedCategories': action.payload,
                'displayRefreshButton': true
            };
        case 'setSelectedCategory':
            return {
                ...state,
                'categories': action.payload.value == "" ? [] : [action.payload.value],
                'selectedCategories': action.payload.value == "" ? [] : [action.payload],
                'selectedCategory': action.payload.value == "" ? undefined : action.payload,
                'displayRefreshButton': true
            };
        case 'displayRefreshButton':
            return {
                ...state,
                'categories': state.selectedCategories.map((item) => item.value),
                'displayResetButton': true,
                'displayRefreshButton': action.payload
            };
        case 'displayCalendars':
            return {
                ...state,
                'displayCalendars': action.payload
            };
        case 'setCalendarValue':
            return {
                ...state,
                'publish_at_from': action.payload.publish_at_from,
                'publish_at_to': action.payload.publish_at_to,
                'displayRefreshButton': true
            };
        case 'setFiltersByHistory':
            return action.payload
        case 'setInitialState': 
            return initialState;
        default:
            return state;
    }
}

const emptyOption = {
    label: '',
    value: ''
}

function isInternalState(object: object): object is InternalState {
    return true
}

function isOption(object: any): object is Option{
    return (object as Option).value !== undefined;
}

const VideoSearch = ( { results, onSearchSubmit }: Props ) => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const apiClient = useApiClient();
    const calendarRef = useRef<HTMLDivElement>(null);
    const btnCalendarRef = useRef<HTMLButtonElement>(null);
    const displayFilters = useContext(DisplayFilterContext);
    const [state, dispatch] = useReducer(reducer, initialState);
    const [categoryOptions, setCategoryOptions] = useState<Option[]>([]);
    const [mainCategoryOptions, setMainCategoryOptions] = useState<PhotoCategoryDto[]>([]);
    const { data } = useQuery('videoCategories', () => apiClient.videoCategories(), { retry: false });
    
    useEffect(() => {
        if (data?.data) {
            if (categoryOptions.length < 1) {
                const optionsArray: Option[] = [];
                data.data.map((category) => {
                    optionsArray.push({ value: category.code, label: category.code + ' - ' + category.title });
                })
                setCategoryOptions(optionsArray);
                setMainCategoryOptions(data.data);
            }
        }
        const searchHistory = localStorage.getItem('videoPage');
        if(searchHistory && searchHistory !== null){
            if(isInternalState(JSON.parse(searchHistory))) {
                dispatch({
                    type: 'setFiltersByHistory', 
                    payload: {
                        ...JSON.parse(searchHistory), 
                        'displayCalendars': false,
                        'displayRefreshButton': false 
                    }});
            }
        }
    }, [data]);

    useEffect(() => {
        function handleClickOutside(event: MouseEvent): void {
            if (
                calendarRef.current && 
                !calendarRef.current.contains(event.target as Node) && 
                btnCalendarRef.current && 
                !btnCalendarRef.current.contains(event.target as Node)
            ) {
                dispatch({ type: 'displayCalendars', payload: false });
            }
        }
        // Bind the event listener
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const submitForm = ({search, categories, publish_at_from, publish_at_to}: VideoGalleriesSearchRequestDto) => {
        dispatch({ type: 'displayRefreshButton', payload: false });
        localStorage.setItem('videoPage', JSON.stringify(state));
        onSearchSubmit({ search, categories, publish_at_from, publish_at_to });
        navigate('/videos');
    };

    const resetFilters = () => {
        dispatch({ type: 'setInitialState', payload: initialState });
        localStorage.setItem('videoPage', JSON.stringify(initialState));
        onSearchSubmit({ search: "", categories: [], publish_at_from: "", publish_at_to: ""});
        navigate('/videos');
    };

    const onSelectChange = (e: Option | RangeKeyDict, type: "category" | "calendar") => {
        switch (type){
            case "category":
                if(isOption(e)){
                    dispatch({type: "setSelectedCategory", payload: e ?? emptyOption})
                    submitForm({
                        search: state.search,
                        categories: e.value ? [e.value] : [],
                        publish_at_from: state.publish_at_from,
                        publish_at_to: state.publish_at_to
                    })
                }
                break;
            case "calendar":
                if(!isOption(e)){
                    const calendarFromValue = e.selection && typeof (e.selection) != "undefined" 
                        ? formatDateToIsoString(new Date((e.selection.startDate ?? new Date()).setHours(0, 0, 0, 0))) 
                        : formatDateToIsoString(new Date(new Date().setHours(0, 0, 0, 0)));
                    const calendarToValue = e.selection && typeof (e.selection) != "undefined" 
                        ? formatDateToIsoString(new Date((e.selection.endDate ?? new Date()).setHours(23, 59, 59, 999))) 
                        : formatDateToIsoString(new Date(new Date().setHours(23, 59, 59, 999)));

                    dispatch({
                        type: 'setCalendarValue',
                        payload: {
                            publish_at_from: calendarFromValue,
                            publish_at_to: calendarToValue
                        }
                    });

                    submitForm({
                        search: state.search,
                        categories: state.selectedCategories.map((item) => item.value),
                        publish_at_from: calendarFromValue,
                        publish_at_to: calendarToValue
                    })
                }
                break;
            default: return;
        }
    }

    return (
        <section className={displayFilters.show ? styles.searchWrapper : styles.searchWrapperScrolling}>
            <div className={displayFilters.show ? styles.searchContainer : styles.searchContainerScrolling}>
                <div className="container">
                    <div className="input-group mb-3">
                        <input type="text" className="form-control"
                            placeholder="Pretraga/Search" aria-label="Pretraga/Search"
                            onChange={(e) => dispatch({ type: "setSearchTerm", payload: e.target.value })}
                            onKeyDown={(e) =>
                                e.key === 'Enter'
                                    ? submitForm({ 
                                        search: state.search,
                                        categories: state.selectedCategories.map((item) => item.value),
                                        publish_at_from: state.publish_at_from,
                                        publish_at_to: state.publish_at_to 
                                    })
                                    : null
                            }
                            value={state.search}
                        />
                        <div className={styles.buttonContainer}>
                            <button className={`${styles.searchButton}`}
                                type="button"
                                id="button-addon2"
                                onClick={() => submitForm({
                                    search: state.search,
                                    categories: state.selectedCategories.map((item) => item.value),
                                    publish_at_from: state.publish_at_from,
                                    publish_at_to: state.publish_at_to,
                                })}
                            >
                                <svg width='19' height='19'>
                                    <use xlinkHref='/theme/font-awesome/solid.svg#magnifying-glass'></use>
                                </svg>
                                <span>{t('search_action')}</span>
                            </button>
                        </div>
                    </div>
                    <div className="search-action d-flex justify-content-between align-items-center">
                        <div className={styles.infoBar}>
                            <span>{results} {t("item_result_label")}</span>
                        </div>
                        <div className={displayFilters.show ? styles.container : styles.containerScrolling}>
                            {
                                mainCategoryOptions.map((option) => {
                                    return (
                                        <div
                                            key={option.code} 
                                            className={styles.categoryLink}
                                            onClick={(e) => onSelectChange({value: option.code, label: option.code + ' - ' + option.title} ?? emptyOption, "category")}
                                        >
                                            {option.title}
                                        </div>
                                    )
                                })
                            }
                            <span className={styles.dates} style={{marginRight: "15px"}}>
                                {state.publish_at_from !== null && state.publish_at_from
                                    ? formatDateToHumanFromString(state.publish_at_from)
                                    : ''
                                }<br></br>
                                {state.publish_at_to !== null && state.publish_at_to
                                    ? formatDateToHumanFromString(state.publish_at_to)
                                    : ''
                                }
                            </span>
                            <button
                                ref={btnCalendarRef}
                                className={`btn ${styles.datepickerButton}`}
                                onClick={() => dispatch({ type: 'displayCalendars', payload: !state.displayCalendars })}
                            >
                                <img src="/theme/img/calendar.png" alt="calendar" width={20} height={20} />
                            </button>
                            {state.selectedCategory !== undefined 
                            ?
                            <Select
                                key={'uq' + state.selectedCategory.value}
                                className={styles.item}
                                onChange={(e) => { onSelectChange(e ?? emptyOption, 'category') }}
                                options={categoryOptions}
                                isClearable={true}
                                placeholder={t('select_categories')}
                                value={state.selectedCategory}
                            />
                            :
                            <Select
                                className={styles.item}
                                onChange={(e) => { onSelectChange(e ?? emptyOption, 'category') }}
                                options={categoryOptions}
                                isClearable={true}
                                placeholder={t('select_categories')}
                                value={undefined}
                            />
                            }
                            <button
                                type="reset"
                                className={`btn ${styles.buttonM} ${styles.refreshButton}`}
                                onClick={() => { resetFilters() }}
                            >
                                <svg className={styles.x} width='13' height='13' fill="white">
                                    <use xlinkHref='/theme/font-awesome/solid.svg#x'></use>
                                </svg>
                                {t('reset_filters')}
                            </button>
                        </div>
                    </div>
                    {state.displayCalendars ?
                        <div 
                            className={styles.calendarWrapper}
                            ref={calendarRef}
                            >
                            <DateRangePicker
                                ranges={[
                                    {
                                        startDate: parse(state.publish_at_from ?? initializedDate, "yyyy-MM-dd'T'HH:mm:ssXXX",  new Date(new Date().setHours(0, 0, 0, 0))),
                                        endDate: parse(state.publish_at_to ?? initializedDate, "yyyy-MM-dd'T'HH:mm:ssXXX", new Date(new Date().setHours(23, 59, 59, 999))),
                                        key: 'selection'
                                    }
                                ]}
                                onChange={(e) => {
                                     onSelectChange(e, 'calendar')
                                    }
                                }
                                maxDate={new Date()}
                                staticRanges={getTotalRanges(i18n.language)}
                                direction="vertical"
                            />
                        </div>
                        : null
                    }
                </div>
            </div>
        </section>
    )
}

export default VideoSearch