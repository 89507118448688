import ServiceDto from "services/api/dto/subscription/ServiceDto";

export enum SubscriptionStatus {
    ACTIVE = "A",
    INACTIVE = "N",
    EXPIRED = "I",
    CANCELLED = "O",
}
export interface SubscriptionDto
{
    uid: string,
    account_uid: string,
    status: string,
    expires_at:string,
    services?: ServiceDto[],
    contract_number?: string,
    contract_date?: string,
    cancelation_contract_number?: string,
    cancelation_contract_date?: string,
}

export default SubscriptionDto;