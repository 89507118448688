import {format} from 'date-fns'

/**
 * It takes a date and returns a string in the format of an ISO 8601 date
 * @param {Date} date - The date to format
 * @returns {string}
 */
export function formatDateToIsoString(date: Date | undefined) {
    return format(date ?? new Date(), "yyyy-MM-dd'T'HH:mm:ssXXX");
}

/**
 * It takes a string, converts it to a date, formats it to a human readable format and returns it
 * @param {string} date - The date to be formatted
 * @returns A function that takes a date and returns a string
 */
export function formatDateTimeToHumanFromString(date: string){
    if(date.length < 5){
        return null;
    }
    return format(new Date(date), "dd/MMM/yyyy HH:mm");
}

/**
 * It takes a string, converts it to a date, formats it to a human readable format and returns it
 * @param {string} date - The date to be formatted
 * @returns A function that takes a date and returns a string
 */
export function formatDateToHumanFromString(date: string){
    return format(new Date(date), "dd/MMM/yyyy");
}

/**
 * It takes a string, converts it to a date, formats it to a human readable format and returns it
 * @param {string} date - The date to be formatted
 * @returns A function that takes a date and returns a string
 */
export function formatTimeToHumanFromString(date: string){
    return format(new Date(date), "HH:mm");
}