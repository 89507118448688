import VideoGalleryDto, { emptyVideoGalleryDto, isVideoGalleryDto } from "services/api/dto/videos/VideoGalleryDto";
import VideoWIdgetSkeleton from "components/loaders/VideoWIdgetSkeleton";
import styles from "styles/video/video-item-view.module.scss";
import { useNavigate, useParams } from "react-router-dom";
import { useApiClient } from "contexts/ApiClientContext";
import { useContext, useEffect, useState } from "react";
import { DisplayFilterContext } from "./VideoWrapper";
import VideoItemView from "./VideoItemView";
import { useQuery } from "react-query";
import { usePageMeta } from "contexts/PageMetaContext";

const VideoItemWidget = () => {
  const { uid } = useParams();
  const navigate = useNavigate();
  const apiClient = useApiClient();
  const displayFilters = useContext(DisplayFilterContext);
  const { isLoading, error, data } = useQuery([uid], () => apiClient.videoGallery(uid), { retry: false });
  const [fetchedItem, setFetchedItem] = useState<VideoGalleryDto>(emptyVideoGalleryDto);

  const { setPageMeta } = usePageMeta();

  useEffect(() => {
    if(!isLoading) {
      if (error) {
        console.log('Greska');
      } else if(data?.data && isVideoGalleryDto(data?.data)) {
        setFetchedItem(data?.data);

        setPageMeta({title: "Video Galerija - " + data.data.title})
      }
    }
  }, [isLoading, error, data]);

  return (
    <div className={ !displayFilters.show && displayFilters.yOffset > 0 ? styles.scrollWrapper : styles.wrapper }>
      <div className={styles.mainWrapper}>
        <span className={styles.close} onClick={() => navigate(`/videos`)}>
          <svg width='17' height='17'>
            <use xlinkHref='/theme/font-awesome/solid.svg#x'></use>
          </svg>
        </span>

        {!isLoading && isVideoGalleryDto(fetchedItem) ? <VideoItemView gallery={fetchedItem} /> : <VideoWIdgetSkeleton />}

      </div>
    </div>
  );
}

export default VideoItemWidget