import { useContext, useEffect, useReducer, useRef, useState } from "react";
import { formatDateToHumanFromString, formatDateToIsoString } from "helpers/formatDateTools";
import { useApiClient } from "contexts/ApiClientContext";
import styles from "styles/common/filters.module.scss";
import { DisplayFilterContext } from "./PhotoGalleriesWrapper";
import { DateRangePicker, RangeKeyDict } from "react-date-range";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import Select from 'react-select';
import { parse } from "date-fns";
import { getTotalRanges } from "helpers/filterHelpers";
import PhotoCategoryDto from "services/api/dto/photos/PhotoCategoryDto";
import PhotoGalleriesSearchRequestDto from "services/api/dto/photos/PhotoGalleriesSearchRequestDto";

const initializedDate = formatDateToIsoString(new Date());

const initialState: InternalState = {
    search: "",
    categoryOptions: [],
    selectedCategories: [],
    selectedCategory: undefined,
    publish_at_from: null,
    publish_at_to: null,
    displayRefreshButton: false,
    displayResetButton: false,
    displayCalendars: false,
}

type FormAction =
    { type: "setSearchTerm", payload: string }
    | { type: "setSelectedCategories", payload: Option[] }
    | { type: "setSelectedCategory", payload: Option }
    | { type: "setCalendarValue", payload: {publish_at_from : string, publish_at_to: string } }
    | { type: "displayCalendars", payload: boolean }
    | { type: "displayRefreshButton" | "displayResetButton", payload: boolean }
    | { type: 'setFiltersByHistory', payload: InternalState }
    | { type: 'setInitialState', payload: InternalState }

interface Option {
    value: string,
    label: string,
}
interface InternalState extends PhotoGalleriesSearchRequestDto {
    categoryOptions: Option[],
    selectedCategories: Option[],
    selectedCategory?: Option,
    displayRefreshButton: boolean,
    displayResetButton: boolean,
    displayCalendars: boolean,
}

interface Props {
    results: number,
    onSearchSubmit: (search: PhotoGalleriesSearchRequestDto) => void
}

function reducer(state: InternalState, action: FormAction): InternalState {
    switch (action.type) {
        case 'setSearchTerm':
            return {
                ...state,
                'search': action.payload,
                'displayRefreshButton': true
            };
        case 'setSelectedCategories':
            return {
                ...state,
                'categories': action.payload.map((item) => item.value),
                'selectedCategories': action.payload,
                'displayRefreshButton': true
            }
        case 'setSelectedCategory':
            return {
                ...state,
                'categories': action.payload.value == "" ? [] : [action.payload.value],
                'selectedCategories': action.payload.value == "" ? [] : [action.payload],
                'selectedCategory': action.payload.value == "" ? undefined : action.payload,
                'displayRefreshButton': true
            }
        case 'displayRefreshButton':
            return {
                ...state,
                'displayResetButton': true,
                'displayRefreshButton': action.payload
            };
        case 'displayCalendars':
            return {
                ...state,
                'displayCalendars': action.payload
            };
        case 'setCalendarValue':
            return {
                ...state,
                'publish_at_from': action.payload.publish_at_from,
                'publish_at_to': action.payload.publish_at_to,
                'displayRefreshButton': true
            };
        case 'setFiltersByHistory':
            return action.payload
        case 'setInitialState': 
            return initialState;
        default:
            return state;
    }
}

const emptyOption = {
    label: '',
    value: ''
}

function isInternalState(object: object): object is InternalState {
    return true
}

function isOption(object: any): object is Option{
    return (object as Option).value !== undefined;
}

const PhotoGalleriesSearch = ( { results, onSearchSubmit }: Props ) => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const apiClient = useApiClient();
    const calendarRef = useRef<HTMLDivElement>(null);
    const btnCalendarRef = useRef<HTMLButtonElement>(null);
    const displayFilters = useContext(DisplayFilterContext);
    const [state, dispatch] = useReducer(reducer, initialState);
    const [categoryOptions, setCategoryOptions] = useState<Option[]>([]);
    const { data } = useQuery('photoCategories', () => apiClient.photoCategories(), { retry: false });

    
    useEffect(() => {
        if (data?.data) {
            if (categoryOptions.length < 1) {
                const optionsArray: Option[] = [];
                data.data.map((category) => {
                    optionsArray.push({ value: category.code, label: category.code + ' - ' + category.title });
                })
                setCategoryOptions(optionsArray);
            }
        }
        const searchHistory = localStorage.getItem('photoGalleriesPage')
        if (searchHistory && searchHistory !== null) {
            if (isInternalState(JSON.parse(searchHistory))) {
                dispatch({
                    type: 'setFiltersByHistory',
                    payload: {
                        ...JSON.parse(searchHistory),
                        'displayCalendars': false,
                        'displayRefreshButton': false
                    }
                });
            }
        }
    }, [data]);

    useEffect(() => {
        function handleClickOutside(event: MouseEvent): void {
            if (
                calendarRef.current && 
                !calendarRef.current.contains(event.target as Node) && 
                btnCalendarRef.current && 
                !btnCalendarRef.current.contains(event.target as Node)
            ) {
                dispatch({ type: 'displayCalendars', payload: false });
            }
        }
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const submitForm = ({ search, categories, publish_at_from, publish_at_to, credits }: PhotoGalleriesSearchRequestDto) => {
        dispatch({ type: 'displayRefreshButton', payload: false });
        localStorage.setItem('photoGalleriesPage', JSON.stringify(state));
        onSearchSubmit({ search, categories, publish_at_from, publish_at_to, credits });
        navigate('/photo-galleries');
    };

    const resetFilters = () => {
        dispatch({ type: 'setInitialState', payload: initialState });
        localStorage.setItem('photoGalleriesPage', JSON.stringify(initialState));
        onSearchSubmit({ search: "", categories: [], publish_at_from: "", publish_at_to: "" });
        navigate('/photo-galleries');
    };

    const onSelectChange = (e: Option | RangeKeyDict, type: "category" | "calendar") => {
        switch (type){
            case "category":
                if(isOption(e)){
                    dispatch({type: "setSelectedCategory", payload: e ?? emptyOption})
                    submitForm({
                        search: state.search,
                        categories: e.value ? [e.value] : [],
                        publish_at_from: state.publish_at_from,
                        publish_at_to: state.publish_at_to,
                        credits: state.selectedCategory?.value != '' ? [state.selectedCategory?.value ?? ''] : undefined
                    })
                }
                break;
            case "calendar":
                if(!isOption(e)){
                    const calendarFromValue = e.selection && typeof (e.selection) != "undefined" 
                        ? formatDateToIsoString(new Date((e.selection.startDate ?? new Date()).setHours(0, 0, 0, 0))) 
                        : formatDateToIsoString(new Date(new Date().setHours(0, 0, 0, 0)));
                    const calendarToValue = e.selection && typeof (e.selection) != "undefined" 
                        ? formatDateToIsoString(new Date((e.selection.endDate ?? new Date()).setHours(23, 59, 59, 999))) 
                        : formatDateToIsoString(new Date(new Date().setHours(23, 59, 59, 999)));

                    dispatch({
                        type: 'setCalendarValue',
                        payload: {
                            publish_at_from: calendarFromValue,
                            publish_at_to: calendarToValue
                        }
                    });

                    submitForm({
                        search: state.search,
                        categories: state.selectedCategories.map((item) => item.value),
                        publish_at_from: calendarFromValue,
                        publish_at_to: calendarToValue,
                        credits: state.selectedCategory?.value != '' ? [state.selectedCategory?.value ?? ''] : undefined
                    })
                }
                break;
            default: return;
        }
    }

    return (
        <section className={displayFilters.show ? styles.searchWrapper : styles.searchWrapperScrolling}>
            <div className={styles.topLinks}>
                <Link className={`${styles.topLink} ${location.pathname.slice(0, 16) == '/photo-galleries' ? styles.active : ''}`} to={'/photo-galleries'}>
                    {t('galleries')} 
                    <img src="/theme/img/gallery.png" alt="gallery" />
                </Link>
                |
                <Link className={`${styles.topLink} ${location.pathname.slice(0, 7) === '/photos' ? styles.active : ''}`} to={'/photos'}>
                    <img src="/theme/img/single-photo.png" alt="photo" />
                    {t('all')}
                </Link>
            </div>
            <div className={displayFilters.show ? styles.searchContainer : styles.searchContainerScrolling}>
                <div className="container">
                    <div className="input-group mb-3">
                        <input type="text" className="form-control"
                            placeholder="Pretraga/Search" aria-label="Pretraga/Search"
                            onChange={(e) => dispatch({ type: "setSearchTerm", payload: e.target.value })}
                            onKeyDown={(e) =>
                                e.key === 'Enter'
                                    ? submitForm({ 
                                        search: state.search,
                                        categories: state.selectedCategory?.value != '' ? [state.selectedCategory?.value ?? ''] : undefined,
                                        publish_at_from: state.publish_at_from,
                                        publish_at_to: state.publish_at_to 
                                    })
                                    : null
                            }
                            value={state.search}
                        />
                        <div className={styles.buttonContainer}>
                            <button className={`${styles.searchButton}`}
                                type="button"
                                id="button-addon2"
                                onClick={() => submitForm({
                                    search: state.search,
                                    credits: state.selectedCategory?.value != '' ? [state.selectedCategory?.value ?? ''] : undefined,
                                    publish_at_from: state.publish_at_from,
                                    publish_at_to: state.publish_at_to,
                                })}
                            >
                                <svg width='19' height='19'>
                                    <use xlinkHref='/theme/font-awesome/solid.svg#magnifying-glass'></use>
                                </svg>
                                <span>{t('search_action')}</span>
                            </button>
                        </div>
                    </div>
                    <div className="search-action d-flex justify-content-between align-items-center">
                        <div className={styles.infoBar}>
                            <span>{results} {t("item_result_label")}</span>
                        </div>
                        <div className={displayFilters.show ? styles.container : styles.containerScrolling}>
                            <span className={styles.dates} style={{marginRight: "15px"}}>
                                {state.publish_at_from !== null && state.publish_at_from
                                    ? formatDateToHumanFromString(state.publish_at_from)
                                    : ''
                                }<br></br>
                                {state.publish_at_to !== null && state.publish_at_to
                                    ? formatDateToHumanFromString(state.publish_at_to)
                                    : ''
                                }
                            </span>
                            <button
                                ref={btnCalendarRef}
                                className={`btn ${styles.datepickerButton}`}
                                onClick={() => dispatch({ type: 'displayCalendars', payload: !state.displayCalendars })}
                            >
                                <img src="/theme/img/calendar.png" alt="calendar" width={20} height={20} />
                            </button>
                            {state.selectedCategory !== undefined 
                                ?
                                <Select
                                    key={'uq' + state.selectedCategory.value}
                                    className={styles.item}
                                    onChange={(e) => { onSelectChange(e ?? emptyOption, "category") }}
                                    options={categoryOptions}
                                    isClearable={true}
                                    placeholder={t('select_categories')}
                                    value={state.selectedCategory}
                                />
                                :
                                <Select
                                    className={styles.item}
                                    onChange={(e) => { onSelectChange(e ?? emptyOption, "category") }}
                                    options={categoryOptions}
                                    isClearable={true}
                                    placeholder={t('select_categories')}
                                    value={undefined}
                                />
                            }
                            <button
                                type="reset"
                                className={`btn ${styles.buttonM} ${styles.refreshButton}`}
                                onClick={() => { resetFilters() }}
                            >
                                <svg className={styles.x} width='13' height='13' fill="white">
                                    <use xlinkHref='/theme/font-awesome/solid.svg#x'></use>
                                </svg>
                                {t('reset_filters')}
                            </button>
                        </div>
                    </div>
                    {state.displayCalendars ?
                        <div
                            className={styles.calendarWrapper}
                            ref={calendarRef}
                        >
                            <DateRangePicker
                                ranges={[
                                    {
                                        startDate: parse(
                                            state.publish_at_from && state.publish_at_from !== null && typeof state.publish_at_from !== 'undefined'
                                            ?
                                            state.publish_at_from
                                            :
                                            initializedDate, "yyyy-MM-dd'T'HH:mm:ssXXX", new Date(new Date().setHours(0, 0, 0, 0))),
                                        endDate: parse(
                                            state.publish_at_to && state.publish_at_to !== null && typeof state.publish_at_to !== 'undefined' 
                                            ?
                                            state.publish_at_to
                                            : initializedDate, "yyyy-MM-dd'T'HH:mm:ssXXX", new Date(new Date().setHours(23, 59, 59, 999))),
                                        key: 'selection'
                                    }
                                ]}
                                onChange={(e) => {
                                    onSelectChange(e, 'calendar')
                                }
                                }
                                maxDate={new Date()}
                                staticRanges={getTotalRanges(i18n.language)}
                                direction="vertical"
                            />
                        </div>
                        : null
                    }
                </div>
            </div>
        </section>
    )
}

export default PhotoGalleriesSearch