import VideoGalleriesSearchRequestDto from "services/api/dto/videos/VideoGalleriesSearchRequestDto";
import { createContext, useCallback, useEffect, useState } from "react";
import CanvasButton from "components/layout/CanvasButton";
import VideoCanvas from "components/video/VideoCanvas";
import VideoSearch from "components/video/VideoSearch";
import Video from "pages/Video";
import { usePageMeta } from "contexts/PageMetaContext";

export const DisplayFilterContext = createContext<{ yOffset: number, show: boolean }>({ yOffset: 0, show: true });

const VideoWrapper = () => {
    const searchHistory = localStorage.getItem('videoPage');
    const [search, setSearch] = useState<VideoGalleriesSearchRequestDto>(JSON.parse(searchHistory ?? "{}") ?? {search: ''});
    const [resultsNumber, setResultsNumber] = useState<number>(0);
    const [displayFilters, setDisplayFilters] = useState<{ yOffset: number, show: boolean }>({ yOffset: 0, show: true });
    const [displayCanvas, setDisplayCanvas] = useState<boolean>(window.innerWidth > 992 ? false : true);

    const { setPageMeta } = usePageMeta();

    useEffect(() => {
        setPageMeta({title: "Video Galerije" })
    }, [])

    const handleScroll = useCallback(() => {
        if (displayFilters.yOffset > window.scrollY && window.innerWidth > 992) {
            setDisplayFilters({ show: true, yOffset: window.scrollY });
        } else {
            setDisplayFilters({ show: false, yOffset: window.scrollY });
        }
    }, [displayFilters]);

    const handleFilters = useCallback(() => {
        if (window.innerWidth > 992) {
            setDisplayCanvas(false);
            setDisplayFilters({ show: true, yOffset: window.scrollY });
        } else {
            setDisplayFilters({ show: false, yOffset: window.scrollY });
            setDisplayCanvas(true);
        }
    }, [displayCanvas]);

    const handleSearchSubmit = (searchObject: VideoGalleriesSearchRequestDto) => {
        setSearch(searchObject);
    }

    const showCanvas = () => {
        const canvas = document.getElementById("canvas");
        if(canvas != null) {
            canvas.style.width = "100%";
        }  
        // const layout = document.getElementsByClassName('grid-layout-video');
        // if(layout[0] instanceof HTMLDivElement) {
        //     layout[0].style.backgroundColor = "rgba(0,0,0,0.4)";
        // }
    }
  
    const hideCanvas = () => {
        const canvas = document.getElementById("canvas");
        if(canvas != null) {
            canvas.style.width = "0";
        }  
    }

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        }
    }, [handleScroll]);

    useEffect(() => {
        window.addEventListener('resize', handleFilters);
    }, [handleFilters]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [search]);
    
    return(
        <main>
            <DisplayFilterContext.Provider value={displayFilters}>
                { displayCanvas ? <VideoCanvas results={resultsNumber} onSearchSubmit={handleSearchSubmit} hideCanvas={hideCanvas} /> : null }
                {
                    !displayCanvas ? 
                    <VideoSearch results={resultsNumber} onSearchSubmit={handleSearchSubmit} /> : 
                    <CanvasButton showCanvas={showCanvas} />
                }
                <Video searchFilters={search} setResultsNumber={setResultsNumber} />
            </DisplayFilterContext.Provider>
        </main>
    )
}

export default VideoWrapper