import { useRef, useEffect } from 'react';
import videojs, { VideoJsPlayer } from 'video.js';
import 'video.js/dist/video-js.css';

const VideoPlayer = (props: any) => {
    const videoRef = useRef(null);
    const playerRef = useRef<VideoJsPlayer | null>(null);
    const {options, onReady} = props;
  
    useEffect(() => {
  
      // Make sure Video.js player is only initialized once
      if (!playerRef.current) {
        const videoElement = videoRef.current;
  
        if (!videoElement) return;
        
        if(playerRef && playerRef !== null){
            const player: VideoJsPlayer = playerRef.current = videojs(videoElement, options, () => {
                videojs.log('player is ready');
                onReady && onReady(player);
              });
        }
      } else {
        const player: VideoJsPlayer = playerRef.current;
        if(options.poster !== '/logo.png' || options.sources[0].src !== '/placeholder.mp4'){
          player.poster(options.poster);
          player.src(options.sources[0].src);
        }
        return;
      }
    }, [options, videoRef]);
  
    // Dispose the Video.js player when the functional component unmounts
    useEffect(() => {
      const player: VideoJsPlayer | null = playerRef.current;
  
      return () => {
        if (player) {
          player.dispose();
          playerRef.current = null;
        }
      };
    }, [playerRef]);
  
    return (
      <div data-vjs-player>
        <video ref={videoRef} className='video-js vjs-big-play-centered' />
      </div>
    );
};

export default VideoPlayer;