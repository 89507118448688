import VideoDto, { emptyVideoDto } from "services/api/dto/videos/VideoDto";
import { formatDateTimeToHumanFromString } from "helpers/formatDateTools";
import VideoGalleryDto from "services/api/dto/videos/VideoGalleryDto";
import styles from "styles/video/video-item-view.module.scss";
import { useApiClient } from "contexts/ApiClientContext";
import ScrollContainer from 'react-indiana-drag-scroll';
import { useEffect, useReducer, useRef } from "react";
import { Link, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { VideoJsPlayer } from "video.js";
import VideoPlayer from "./VideoPlayer";
import { toast } from "react-toastify";
import Scroller from "components/layout/Scroller";
import { usePageMeta } from "contexts/PageMetaContext";

type Action = {
    type: "setMainVideo", 
    payload: VideoDto | undefined
} | { 
    type: "setOtherVideos", 
    payload: VideoDto[] 
} | { 
    type: "setPlayerVideo", 
    payload: PlayerVideo 
} | { 
    type: "setPrevVideo", 
    payload: VideoDto[] 
} | { 
    type: "setNextVideo", 
    payload: VideoDto[] 
} | { 
    type: "setShowNextAndPrev", 
    payload: boolean
}

interface ThisState {
    mainVideo: VideoDto | undefined,
    prevVideo: VideoDto | undefined,
    nextVideo: VideoDto | undefined,
    showNextAndPrev: boolean,
    otherVideos: VideoDto[],
    playerVideo: PlayerVideo
}

interface Props {
    gallery: VideoGalleryDto,
}

interface PlayerVideo {
    autoplay?: boolean,
    controls?: boolean,
    responsive?: boolean,
    poster: string,
    fluid?: boolean,
    sources: [{
      src: string,
      type: string
    }]
}

const firstState: ThisState = {
    mainVideo: emptyVideoDto,
    prevVideo: emptyVideoDto,
    nextVideo: emptyVideoDto,
    showNextAndPrev: false,
    otherVideos: [],
    playerVideo: {
        autoplay: false,
        controls: true,
        responsive: true,
        poster: "/logo.png",
        fluid: true,
        sources: [{
          src: '/placeholder.mp4',
          type: 'video/mp4'
        }]
    }

}

function reducer(state: ThisState, action: Action): ThisState {
    switch (action.type) {
        case 'setMainVideo':
            return {
                ...state,
                'mainVideo': action.payload,
                'playerVideo': {
                    autoplay: false,
                    controls: true,
                    responsive: true,
                    poster: action.payload?.files?.find((item) => item.label == 'photo_watermark')?.url ?? firstState.playerVideo.poster,
                    fluid: true,
                    sources: [{
                      src: action.payload?.files?.find((item) => item.label == 'preview')?.url ?? firstState.playerVideo.sources[0].src,
                      type: 'video/mp4'
                    }]
                }
            }
        case 'setOtherVideos':
            return {
                ...state,
                'otherVideos': action.payload,
            }
        case 'setPrevVideo':
            return {
                ...state,
                'prevVideo': 
                            (action.payload.indexOf(state.mainVideo ?? emptyVideoDto) != 0) ? 
                            action.payload[action.payload.indexOf(state.mainVideo ?? emptyVideoDto) - 1] : 
                            action.payload[action.payload.length - 1],
            }
        case 'setNextVideo':
            return {
                ...state,
                'nextVideo': 
                            (action.payload.indexOf(state.mainVideo ?? emptyVideoDto) != (action.payload.length - 1)) ? 
                            action.payload[action.payload.indexOf(state.mainVideo ?? emptyVideoDto) + 1] : 
                            action.payload[0],
            }
        case 'setPlayerVideo':
            return {
                ...state,
                'playerVideo': action.payload,
            }
        case 'setShowNextAndPrev':
            return {
                ...state,
                'showNextAndPrev': action.payload,
            }
        default:
            return state
    }
}

const VideoItemView = ( { gallery }:Props ) => {
    const { t } = useTranslation();
    const apiClient = useApiClient();
    const { videoUid } = useParams();
    const [state, dispatch] = useReducer(reducer, firstState);
    const vieWRef = useRef<HTMLDivElement>(null);
    const playerRef = useRef<VideoJsPlayer | null>(null);
    const downloadRef = useRef<HTMLAnchorElement>(null);

    const { setPageMeta } = usePageMeta();

    useEffect(() => {
        if(videoUid == undefined) {
            dispatch({ type: 'setMainVideo', payload: 
                gallery.videos?.filter((video) => video.uid === gallery.leading_video_uid)[0]
                ??
                (gallery?.videos?.length ?? 0 > 1 
                ? gallery?.videos?.[0] 
                : emptyVideoDto) });
            dispatch({ type: 'setOtherVideos', payload: gallery?.videos ?? [] });
            dispatch({ type: 'setPrevVideo', payload: gallery?.videos ?? [] });
            dispatch({ type: 'setNextVideo', payload: gallery?.videos ?? [] });
        } else {
            const leadUid: string = videoUid;
            const leadVideo = gallery?.videos?.find((item) => { return item.uid == leadUid});
            if (leadVideo) {
                setPageMeta({title: "Video - "  + leadVideo.title})
            }
            dispatch({ type: 'setMainVideo', payload: leadVideo });
            dispatch({ type: 'setOtherVideos', payload: gallery?.videos ?? [] });
            dispatch({ type: 'setPrevVideo', payload: gallery?.videos ?? [] });
            dispatch({ type: 'setNextVideo', payload: gallery?.videos ?? [] });
        }
        vieWRef.current?.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    }, [gallery, videoUid]);

    const handlePlayerReady = (player: VideoJsPlayer) => {
        playerRef.current = player;
        
        player.on('waiting', () => {
            console.log('hello');
        });

        player.on('dispose', () => {
            console.log('goodbye');
        });
    };

    const downloadVideo = async () => {
        try {
            const response = await apiClient.downloadVideo({uid: state.mainVideo?.uid ?? "", label: 'watermark'});
            if(response && response?.data?.url){
                    const downloadLink: HTMLAnchorElement = document.createElement('a');
                    document.body.append(downloadLink);
                    downloadLink.href = response.data.url;
                    downloadLink.download = state.mainVideo && state.mainVideo.orig_file_name 
                                        ? state.mainVideo.orig_file_name
                                        : 'video.mp4'
                    downloadLink.text = "Video -  " + state.mainVideo?.title;
                    downloadLink.click();
                    downloadLink.remove();
                    toast.success(`${t("download_started_single")}`, {autoClose: 1000, position: "top-left"})
            } else {
                toast.error(t('server_error'));
            }
        } catch (errors: any) {
            switch (errors.response.status) {
                case 403:
                    toast.error(t('constrained_item'));
                    break;
                default:
                    break;
            }
        }
    }

    return (
        <>
            <div className={styles.header}>
                <div>
                    <span>{gallery?.publish_at ? formatDateTimeToHumanFromString(gallery?.publish_at) : gallery?.publish_at}</span>
                </div>
                <div>
                    <a href="#" onClick={ async () => await downloadVideo() }>
                        <svg width='15' height='15'>
                            <use xlinkHref='/theme/font-awesome/solid.svg#download'></use>
                        </svg>
                        {t("download")} {Math.round(((state.mainVideo?.files?.find((item) => item.label == 'watermark')?.size ?? 0) / 1024 / 1024) * 100) / 100} MB
                    </a>
                </div>
            </div>
            <div 
                className={styles.content}
                ref={vieWRef}
                >
                <div className={styles.contentTop}>
                    {/* VIDEO PLAYER FOR MAIN VIDEO */}
                    <div 
                        onMouseOver={() => dispatch({ type: 'setShowNextAndPrev', payload: true })} 
                        onMouseOut={() => dispatch({ type: 'setShowNextAndPrev', payload: false })}
                        className={styles.videoPlayerWrapper} 
                        style={{position: "relative"}}
                        >
                        { 
                        state.showNextAndPrev ? 
                        <Link to={`/videos/${gallery.uid}/${state.prevVideo?.uid}`} className={styles.prevLink}>
                            <div key={state.prevVideo?.uid}>
                                <svg width="24" height="24" fill="black">
                                    <use xlinkHref="/theme/font-awesome/solid.svg#angle-left"></use>
                                </svg>
                            </div>
                        </Link>
                        : null
                        }
                        {
                            <VideoPlayer 
                                options={state.playerVideo}
                                onReady={handlePlayerReady}
                                /> 
                        }
                        { 
                        state.showNextAndPrev ? 
                        <Link to={`/videos/${gallery.uid}/${state.nextVideo?.uid}`} className={styles.nextLink}>
                            <div key={state.nextVideo?.uid}>
                                <svg width="24" height="24" fill="black">
                                    <use xlinkHref="/theme/font-awesome/solid.svg#angle-right"></use>
                                </svg>
                            </div>
                        </Link>
                        : null
                        }
                    </div>
                    <h2 style={{marginTop: '10px', marginBottom: '0'}}>{ state.mainVideo?.title }</h2>
                </div>
                <div className={styles.contentSlider}>
                    <p>{t("gallery") + " (" + gallery.items_count + ") : " + gallery?.title}</p>
                    {state.otherVideos.length > 3 && document.body.clientWidth > 786
                    ? 
                    <Scroller targetClass={styles.sliderWrapper}/> 
                    : 
                    ''}
                    <ScrollContainer className={styles.sliderWrapper} style={{position: "relative", contain: "content"}}>
                        
                        { state.otherVideos?.map((singleVideo) => 
                            <Link key={singleVideo?.uid} to={`/videos/${gallery.uid}/${singleVideo?.uid}`} className={styles.sliderItem}>
                                <div key={singleVideo?.uid}>
                                    <figure className={styles.figure} key={singleVideo?.uid}>
                                        <img className={(singleVideo?.uid == videoUid ? styles.activeSlider : "")} key={singleVideo?.uid} src={ singleVideo.files.find((item) => item.label == 'photo_thumb')?.url } alt={singleVideo.orig_file_name} />
                                    </figure>
                                    <p>{singleVideo?.title}</p>
                                </div>
                            </Link>
                        )}
                    </ScrollContainer>
                </div>
                <div className={styles.contentMiddle}>
                    {gallery.description}
                </div>
                <div className={styles.contentBottom}>
                    <div className="d-flex justify-content-between">
                        <span>{t('credit')}:</span>
                        <span>{state.mainVideo?.credit}</span>
                    </div>
                    <div className="d-flex justify-content-between">
                        <span>{t('language')}:</span>
                        <span>{state.mainVideo?.lang}</span>
                    </div>
                    <div className="d-flex justify-content-between">
                        <span>{t('cameraman')}:</span>
                        <span>
                            {
                                state.mainVideo?.cameraman !== null || state.mainVideo.cameraman !== 'null' ? state.mainVideo?.cameraman : '' 
                                + (state.mainVideo !== undefined && 
                                    (
                                    state.mainVideo?.cameraman_initials !== '' || 
                                    state.mainVideo.cameraman_initials !== null ||
                                    state.mainVideo.cameraman_initials !== 'null'
                                    ) 
                                    ? 
                                    ( " (" + state.mainVideo?.cameraman_initials + ")") : "") 
                                    }
                                    </span>
                    </div>
                    <div className="d-flex justify-content-between">
                        <span>{t('source')}:</span>
                        <span>{state.mainVideo?.source}</span>
                    </div>
                    <div className="d-flex justify-content-between">
                        <span>{t('copyright')}:</span>
                        <span>{state.mainVideo?.copyright}</span>
                    </div>
                    <div className="d-flex justify-content-between">
                        <span>{t('categories')}:</span>
                        <span>{(state.mainVideo?.video_categories_codes ?? []).join(' - ')}</span>
                    </div>
                </div>
            </div>
            <a ref={downloadRef} className="d-none" href=""></a>
        </>
    )
}

export default VideoItemView