import { BrowserRouter } from 'react-router-dom'
import AuthContextProvider from './contexts/AuthContext';
import ApiClientProvider from './contexts/ApiClientContext';
import { QueryClient, QueryClientProvider } from 'react-query';
import Router from './routes/Router';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import GoogleAnalyticsContextProvider from './contexts/GoogleAnalyticsContext';
import PageMetaContextProvider from 'contexts/PageMetaContext';

const queryClient = new QueryClient();

function App() {  
  return (
    <BrowserRouter>
      <PageMetaContextProvider>
      <AuthContextProvider>
        <GoogleAnalyticsContextProvider>
        <ToastContainer />
        <ApiClientProvider>
          <QueryClientProvider client={queryClient}>
            <Router />
          </QueryClientProvider>
        </ApiClientProvider>
        </GoogleAnalyticsContextProvider>
      </AuthContextProvider>
      </PageMetaContextProvider>
    </BrowserRouter>
  );
}

export default App;
