import FileDto from "../FileDto";
import VideoDto from "./VideoDto";

export const emptyVideoGalleryDto = {
    uid: "",
    orig_file_name: "",
    publish_at: "",
    headline: "",
    lead: "", 
    title: "",
    lang: "",
    description: "",
    credit: "",
    source: "",
    copyright: "",
    cameraman: "",
    cameraman_initials: "",
    leading_video_uid: "",
    video_category_code: "",
    video_categories_codes: [],
    review_by_initials: "",
    items_count: 0,
    videos: [],
    files: []
}

export function isVideoGalleryDto(object: any): object is VideoGalleryDto {
    return true;
} 

export default interface VideoGalleryDto {
    uid: string,
    orig_file_name: string,
    publish_at: string,
    headline?: string,
    lead?: string, 
    title?: string,
    lang?: string,
    description?: string,
    leading_video_uid: string,
    credit?: string,
    source?: string,
    copyright?: string,
    cameraman?: string,
    cameraman_initials?: string,
    video_category_code?: string,
    video_categories_codes?: string[],
    review_by_initials?: string,
    items_count?: number,

    videos?: VideoDto[],
    files?: FileDto[]
}