import ArticleDto, { emptyNewsDto, isNewsDto } from "services/api/dto/news/ArticleDto";
import NewsWIdgetSkeleton from "components/loaders/NewsWIdgetSkeleton";
import { useNavigate, useParams } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { DisplayFilterContext } from "./IndexWrapper";
import NewsItemView from "./NewsItemView";
import { useQuery } from "react-query";
import { useApiClient } from "contexts/ApiClientContext";
import { usePageMeta } from "contexts/PageMetaContext";

const NewsItemWidget = () => {
  const { uid } = useParams();
  const navigate = useNavigate();
  const apiClient = useApiClient();
  const displayFilters = useContext(DisplayFilterContext);
  const { isLoading, error, data } = useQuery([uid], () => apiClient.article(uid), { retry: false });
  const [fetchedItem, setFetchedItem] = useState<ArticleDto>(emptyNewsDto);

  const { setPageMeta } = usePageMeta();

  useEffect(() => {
    if(!isLoading) {
      if (error) {
        console.log('Greska');
      } else if(data?.data && isNewsDto(data?.data)) {
        setFetchedItem(data?.data);
        
        //setPageMeta({title: "Vest - " + data.data.lead})
        setPageMeta({title: "Vest - " + (data.data.news_category_code ? data.data.news_category_code + ":" : "") + data.data.slug})
      }
    }
  }, [isLoading, error, data]);

  return (
    <div className={!displayFilters.show && displayFilters.yOffset > 0 ? 'grid-scroll-wrapper' : 'grid-sidebar-wrapper' } >
      <div className='grid-sidebar-main-wrapper'>
        <span className="close" onClick={() => navigate(`/news`)}>
          <svg width='17' height='17'>
            <use xlinkHref='/theme/font-awesome/solid.svg#x'></use>
          </svg>
        </span>

        {!isLoading && isNewsDto(fetchedItem) ? <NewsItemView news={fetchedItem} /> : <NewsWIdgetSkeleton />}

      </div>
    </div>
  );
}

export default NewsItemWidget