import { formatDateTimeToHumanFromString } from "helpers/formatDateTools";
import VideoGalleryDto from "services/api/dto/videos/VideoGalleryDto";
import { Link, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

interface Props {
    gallery: VideoGalleryDto,
    handleShowSideBar(): void,
}

const VideoItem = ({ gallery, handleShowSideBar }: Props) => {
    const { uid } = useParams();
    const { t } = useTranslation();
    return (
        <>
        <Link to={`/videos/${gallery.uid}`} style={{ all: "unset", cursor: "pointer" }}>
            <div className={`layout-item ${uid === gallery.uid ? 'active' : ''}`} onClick={() => handleShowSideBar()}>
                <div className="layout-item-data">
                    <div className="main-img">
                        <figure className="video-figure">
                            <img className="video-img" style={{width: "100%", height: "100%"}} src={gallery?.files?.find((item) => item?.label == 'photo_thumb')?.url} alt={ gallery?.files?.find((item) => item?.label == 'photo_thumb')?.name } />
                        </figure>
                    </div>
                </div>
                <div className="layout-item-info">
                    <div>
                        <h4>{formatDateTimeToHumanFromString(gallery.publish_at)}</h4>
                        <h2>{ gallery?.title }</h2>
                    </div>
                    <div>
                        <span>{ (gallery?.video_categories_codes ?? []).join(' ') }</span>
                    </div>
                </div>
                <div className="layout-item-action">
                    <div className="action-count">
                        { "(" + gallery.items_count + ")" }
                    </div>
                    <svg width='16' height='16' fill="#191c38">
                        <use xlinkHref='/theme/font-awesome/solid.svg#video'></use>
                    </svg>
                </div>
            </div>
        </Link>
        </>
    )
}

export default VideoItem