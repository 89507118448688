import axios from "axios";

const BASE_URL = process.env.REACT_APP_API_BASE_URL

export const axiosPrivate = axios.create({
    baseURL: BASE_URL,
    headers: {
        "Content-type": "application/json",
    }
});

export const axiosPublic = axios.create({
    baseURL: BASE_URL,
    headers: {
        "Content-type": "application/json",
    }
});