import { createContext, ReactElement, useContext } from "react";
import ApiClient from "services/api/AxiosApiClient";
import AxiosApiClient from "services/api/AxiosApiClient";
import { AxiosInstance } from "axios";
import useAxiosPrivate from "hooks/useAxiosPrivate";

export interface ApiClientContextProps {
    apiClient?: ApiClient,
}

export const ApiClientContext = createContext<ApiClientContextProps>({});

interface Props {
    apiClient?: ApiClient,
    children: ReactElement
}

export function createPrivateApiClient(privateClient: AxiosInstance): ApiClient {

    const httpClient: AxiosInstance = privateClient;

    const apiClient: AxiosApiClient = new AxiosApiClient(httpClient)

    return apiClient
}

export const ApiClientProvider = ({ children }: Props) => {
    const privateClient = useAxiosPrivate();
    const apiClient = createPrivateApiClient(privateClient);

    return (
        <ApiClientContext.Provider value={{ apiClient: apiClient }}>
            {children}
        </ApiClientContext.Provider>
    )
}

export function useApiClient(): Required<ApiClient> {
    const apiClientContextProps: ApiClientContextProps = useContext<ApiClientContextProps>(ApiClientContext);

    if (!apiClientContextProps.apiClient) {
        throw new Error('Api client is not initialized');
    }

    return apiClientContextProps.apiClient;
}




export default ApiClientProvider
