import { Link, useNavigate, useParams } from "react-router-dom";
import PhotoDto from "services/api/dto/photos/PhotoDto";
import styles from 'styles/photo/list-layout.module.scss';
import { saveAs } from 'file-saver';
import { formatDateTimeToHumanFromString } from "helpers/formatDateTools";
import { useApiClient } from "contexts/ApiClientContext";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

interface Props {
    photo: PhotoDto,
    handleShowSideBar(): void,
    onSelectPhoto: (photo: PhotoDto) => void,
    checked: boolean
}

const PhotoItemList = ({ photo, handleShowSideBar, onSelectPhoto, checked }: Props) => {
    const { uid } = useParams();
    const { t } = useTranslation();
    const apiClient = useApiClient();

    const downloadPhoto = async () => {
        try {
            const response = await apiClient.downloadPhoto({uid: photo.uid, label: 'orig'});
            if(response && response?.data?.url){
                saveAs(response?.data?.url ?? "", photo?.orig_file_name ?? "download_file");
                toast.success(t('download_started'), {pauseOnHover: false, hideProgressBar: true, autoClose: 2000});
            } else {
                toast.error(t('server_error'));
            }
        } catch (errors: any) {
            switch (errors.response.status) {
                case 403:
                    toast.error(t('constrained_item'));
                    break;
                default:
                    break;
            }
        }
    }

    return (
        <>
            <div className={`${styles.item}${uid === photo.uid ? " " + styles.itemActive : ""}`}>
                <Link to={`/photos/${photo.uid}`} style={{textDecoration: 'none', cursor: 'pointer'}} className={styles.linkWrap} onClick={() => handleShowSideBar()}>
                    <div className={styles.itemData}>
                        <div className={styles.mainImg}>
                            <figure className={styles.figureImage}>
                                <img 
                                    src={photo.files.find((item) => item.label == 'thumb')?.url}
                                    alt={photo.files.find((item) => item.label == 'thumb')?.name} 
                                />
                            </figure>
                        </div>
                    </div>
                </Link>
                <div className={styles.itemInfo}>
                    <div className={styles.itemInfoHeader}>
                        <Link to={`/photos/${photo.uid}`} style={{all: 'unset', cursor: 'pointer', width: '90%', display: 'flex', alignItems: 'center'}} onClick={() => handleShowSideBar()}>
                            <h4>{ formatDateTimeToHumanFromString(photo.publish_at) }</h4>
                            <h2>{!uid ? photo?.title : null}</h2>
                        </Link>
                        <div className={styles.itemAction}>
                            <div className={styles.actionItem}>
                                <svg fill="blue" width='20' height='20' onClick={async () => await downloadPhoto()}>
                                    <use fill="#191c38" xlinkHref='/theme/font-awesome/solid.svg#download'></use>
                                </svg>
                            </div>
                            <div className={styles.actionItem}>
                                <input type="checkbox"
                                    name="photo"
                                    id={photo.uid}
                                    onChange={() => onSelectPhoto(photo)}
                                    checked={checked}
                                />
                            </div>
                        </div>
                        {/* <h2>{ photo?.title }</h2> */}
                    </div>
                    <Link to={`/photos/${photo.uid}`} style={{all: 'unset', cursor: 'pointer'}} className={styles.linkWrap} onClick={() => handleShowSideBar()}>
                        <div className={styles.itemInfoDescription}>
                            { photo?.description }
                        </div>
                    </Link>
                </div>
            </div>
        </>
    )
}

export default PhotoItemList