import { subDays } from "date-fns";
import { createStaticRanges, StaticRange } from "react-date-range";

export const getTotalRanges = (lang: string): StaticRange[] => {
    /* 'react-date-range' helpers */
    const staticRanges: StaticRange[] = [
        {
            label: lang == 'sr' ? "Danas" : "Today",
            isSelected: () => false,
            range: () => ({
                startDate: new Date(),
                endDate: new Date()
            })
        },
        {
            label:  lang == 'sr' ? "Poslednjih 7 dana" : "Last 7 days",
            isSelected: () => false,
            range: () => ({
                startDate: subDays(new Date(), 7),
                endDate: new Date()
            })
        },
        {
            label:  lang == 'sr' ? "Poslednjih 15 dana" : "Last 15 days",
            isSelected: () => false,
            range: () => ({
                startDate: subDays(new Date(), 15),
                endDate: new Date()
            })
        },
        {
            label:  lang == 'sr' ? "Poslednjih 30 dana" : "Last 30 days",
            isSelected: () => false,
            range: () => ({
                startDate: subDays(new Date(), 30),
                endDate: new Date()
            })
        }
    ];

    return [...createStaticRanges(staticRanges)];
};