import { useAuth } from 'contexts/AuthContext'
import { useLocation, Navigate, Outlet } from 'react-router-dom'
import Layout from 'components/Layout'
import 'styles/main.scss'

const Protected = () => {
    const { auth } = useAuth();
    const location = useLocation();

    return (
        <>
            {auth?.accessToken?.access_token
                ?
                <div className=''>
                    <Layout >
                        <Outlet />
                    </Layout>
                </div>
                : <Navigate to={'/login'} state={{ from: location }} />
            }
        </>
    )
}

export default Protected