import { useApiClient } from "contexts/ApiClientContext";
import { usePageMeta } from "contexts/PageMetaContext";
import { formatDateTimeToHumanFromString } from "helpers/formatDateTools";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQueries } from "react-query";
import { SubscriptionStatus } from "services/api/dto/subscription/SubscriptionDto"

/* setting subscription types, active property is translation key for i18next */
type Status = 
    { active?: "active", color?: "green"}
    | { active?: "inactive", color?: "yellow" }
    | { active?: "expired", color?: "black" }
    | { active?: "cancelled", color?: "red"}

const Subscription = () => {
    const apiClient = useApiClient();
    const { t } = useTranslation();
    const [status, setStatus] = useState<Status>({})
    const subscriptionQueries = useQueries([
        { queryKey: 'subscription', queryFn: () => apiClient.subscription() },
        { queryKey: 'subscriptionAccount', queryFn: () => apiClient.subscriptionAccount() }
    ])

    const { setPageMeta } = usePageMeta();

    useEffect(() => {
        setPageMeta({title: "Pretplata" })
    }, [])

    useEffect(() => {
        switch (subscriptionQueries[0].data?.data?.status) {
            case SubscriptionStatus.ACTIVE:
                setStatus({active: "active", color: "green"})
                break;
            case SubscriptionStatus.INACTIVE:
                setStatus({active: "inactive", color: "yellow"})
                break;
            case SubscriptionStatus.EXPIRED:
                setStatus({active: "expired", color: "black"})
                break;
            case SubscriptionStatus.CANCELLED:
                setStatus({active: "cancelled", color: "red"})
                break;
            default:
                setStatus({active: "cancelled", color: "red"})
        }
    }, [subscriptionQueries[0].data?.data])

    return (
        <section className="profile">
            <div className="container">
                <div className="profile-wrapper">
                    <div className="profile-header">
                        <h2>Pretplata</h2>
                        <div className="header-wrapper">
                            <div className="profile-service">
                                <p>Pretplata</p>
                                <p>Subscription</p>
                            </div>
                            <div className="profile-service details">
                                <p>Detalji naloga</p>
                                <p>Account Details</p>
                            </div>
                        </div>
                    </div>
                    <div className="profile-content">
                        <div className="content-wrapper">
                            <div className="profile-service">
                                <div className="sub-row">
                                    <div className="title">Status:</div>
                                    <span style={{"color": status.color}}>{t(`${status.active}`)}</span>
                                </div>
                                <div className="sub-row">
                                    <div className="title">{t('subscription_expires')}:</div>
                                    <span>{formatDateTimeToHumanFromString(subscriptionQueries[0].data?.data?.expires_at ?? '')}</span>
                                </div>
                                <div className="sub-row services">
                                    <div className="title">{t('subscription_services')}:</div>
                                    <div className="services-grid">
                                        <div className="grid-item">
                                            <div className="service-title">
                                                <svg fill="#1c2e6d" width='16' height='24'>
                                                    <use xlinkHref='/theme/font-awesome/solid.svg#paper-plane'></use>
                                                </svg>
                                                {t('articles')}
                                            </div>
                                            {(subscriptionQueries[0].data?.data?.services?.
                                                filter((item) => item.media == "article").
                                                map((item, idx) => 
                                                    <div className="service-item" key={idx+'a'}>
                                                        <div className="service-item-title">
                                                            {item.title}
                                                            <br />
                                                        </div>
                                                        <div className="service-item-description">
                                                            {item.description}
                                                            <br />
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                        <div className="grid-item">
                                            <div className="service-title">
                                                <svg fill="#1c2e6d" width='16' height='24'>
                                                    <use xlinkHref='/theme/font-awesome/solid.svg#camera'></use>
                                                </svg>
                                                {t('photo')}
                                            </div>
                                            {(subscriptionQueries[0].data?.data?.services?.
                                                filter((item) => item.media == "photo").
                                                map((item, idx) => 
                                                    <div className="service-item" key={idx+'a'}>
                                                        <div className="service-item-title">
                                                            {item.title}
                                                            <br />
                                                        </div>
                                                        <div className="service-item-description">
                                                            {item.description}
                                                            <br />
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                        <div className="grid-item">
                                            <div className="service-title">
                                                <svg fill="#1c2e6d" width='16' height='24'>
                                                    <use xlinkHref='/theme/font-awesome/solid.svg#video'></use>
                                                </svg>
                                                {t('video')}
                                            </div>
                                            {(subscriptionQueries[0].data?.data?.services?.
                                                filter((item) => item.media == "video").
                                                map((item, idx) => 
                                                    <div className="service-item" key={idx+'a'}>
                                                        <div className="service-item-title">
                                                            {item.title}
                                                            <br />
                                                        </div>
                                                        <div className="service-item-description">
                                                            {item.description}
                                                            <br />
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                        <div className="grid-item">
                                            <div className="service-title">
                                                <svg fill="#1c2e6d" width='16' height='24'>
                                                    <use xlinkHref='/theme/font-awesome/solid.svg#volume-high'></use>
                                                </svg>
                                                {t('audio')}
                                            </div>
                                            {(subscriptionQueries[0].data?.data?.services?.
                                                filter((item) => item.media == "phono").
                                                map((item, idx) => 
                                                    <div className="service-item" key={idx+'a'}>
                                                        <div className="service-item-title">
                                                            {item.title}
                                                            <br />
                                                        </div>
                                                        <div className="service-item-description">
                                                            {item.description}
                                                            <br />
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="profile-service mobile">
                                <p>Detalji naloga</p>
                                <p>Account Details</p>
                            </div>
                            <div className="profile-service">
                                <div className="sub-row">
                                    <div className="title">{t('subscription_code')}:</div>
                                    <span>{subscriptionQueries[1].data?.data?.code}</span>
                                </div>
                                <div className="sub-row">
                                    <div className="title">{t('subscription_name')}:</div>
                                    <span>{subscriptionQueries[1].data?.data?.title}</span>
                                </div>
                                <div className="sub-row">
                                    <div className="title">{t('subscription_company_id')}:</div>
                                    <span>{subscriptionQueries[1].data?.data?.company_id}</span>
                                </div>
                                <div className="sub-row">
                                    <div className="title">{t('subscription_company_tin')}</div>
                                    <span>{subscriptionQueries[1].data?.data?.company_tin}</span>
                                </div>
                                <div className="sub-row">
                                    <div className="title">{t('subscription_company_group')}:</div>
                                    <span>{subscriptionQueries[1].data?.data?.company_group}</span>
                                </div>
                                <div className="sub-row">
                                    <div className="title">{t('subscription_company_ceo')}:</div>
                                    <span>{subscriptionQueries[1].data?.data?.company_ceo}</span>
                                </div>
                                <div className="sub-row">
                                    <div className="title">{t('subscription_contact')}:</div>
                                    <span>{subscriptionQueries[1].data?.data?.contact_phone}<br />{subscriptionQueries[1].data?.data?.contact_email}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>)
}

export default Subscription