import React, { useState, useEffect, Dispatch, SetStateAction } from "react";
import { useApiClient } from "contexts/ApiClientContext";
import { useInfiniteQuery } from "react-query";
import { useInView } from 'react-intersection-observer'
import NewsSkeleton from "components/loaders/NewsLoader";
import LoaderIcon from "components/loaders/NewsSkeleton";
import { Outlet, useParams } from "react-router-dom";
import styles from 'styles/fono/fono-forbidden.module.scss'
import { useTranslation } from "react-i18next";
import FonoSearchRequestDto from "services/api/dto/fono/FonoSearchRequestDto";
import FonoItem from "components/fono/FonoItem";

interface Props {
    searchFilters: FonoSearchRequestDto,
    setResultsNumber: Dispatch<SetStateAction<number>> 
}

const Index = ({ searchFilters, setResultsNumber }: Props) => {
    const { uid } = useParams();
    const { t } = useTranslation();
    const apiClient = useApiClient();
    const { ref, inView } = useInView();
    const [showSideBar, setShowSideBar] = useState<boolean>(uid ? true : false);
    const {
        isLoading,
        error,
        data,
        isFetchingNextPage,
        fetchNextPage,
        hasNextPage
    } = useInfiniteQuery(['fono', searchFilters], ({ pageParam }) => apiClient.fono(pageParam, searchFilters), {
        retry: false,
        getNextPageParam: lastPage => lastPage.meta?.next_page !== undefined && lastPage.meta.next_page > 0 ? lastPage.meta.next_page : false
    });

    useEffect(() => {
        if (!uid) {
            setShowSideBar(false);
        }
        if (inView && hasNextPage) {
            fetchNextPage();
        }
    }, [uid, inView]);

    useEffect(() => {
        setResultsNumber(data?.pages[0].meta?.total_rows ?? 0)
    }, [data])

    const handleShowSideBar = (): void => {
        if (uid) {
            setShowSideBar(true);
        } else {
            setShowSideBar(!showSideBar);
        }
    };

    return (
        <section className="grid-layout-fono">
            <div className="container">
                <div className="grid-layout-wrapper">
                    <div className={`grid-item-wrapper ${showSideBar ? 'active' : ''}`}>
                        {
                            (isLoading || error) ? <NewsSkeleton /> :
                                data?.pages.map((page, idx) =>
                                    <React.Fragment key={idx}>
                                        <>
                                            {page.data?.map((fonoItem) =>
                                                <FonoItem key={fonoItem.uid} fono={fonoItem} handleShowSideBar={handleShowSideBar} />
                                            )}
                                        </>
                                    </React.Fragment>
                                )
                        }
                    </div>
                    {showSideBar ? <Outlet /> : null}
                </div>
                {document.body.clientWidth < 768 && uid 
                ?
                ''
                :
                <div ref={ref} className="loader">
                    {isFetchingNextPage ? <LoaderIcon /> : (uid ? '' : <span>{t('no_more_results')}</span>)}
                </div>
                }
            </div>
            {
                error ? 
                <div className={styles.container}>
                    <h1 className={styles.text}>
                        {t('not_subscribe_message')}
                    </h1>
                </div>
                :
                null
            }
        </section>
    )
}

export default Index;