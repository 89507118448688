import { axiosPrivate } from "services/api/axios";
import useRefreshToken from "hooks/useRefreshToken";
import { useAuth } from "contexts/AuthContext";
import { AxiosInstance } from "axios";
import { AuthObject } from "contexts/AuthContext";
import { useLocation } from "react-router-dom";
import { toast } from 'react-toastify';
import { useTranslation } from "react-i18next";

const useAxiosPrivate = (): AxiosInstance => {
    const refresh = useRefreshToken();
    const { auth, setAuth } = useAuth();
    const { t } = useTranslation();
    const location = useLocation();

    axiosPrivate.interceptors.request.use(
        config => {
            if (config.headers !== undefined && location.pathname !== '/login') {
                if (!config.headers['authorization']) {
                    if (!auth?.accessToken?.access_token && !localStorage.getItem('access_token')) {
                        return config;
                    } else {
                        config.headers['authorization'] = `Bearer ${auth?.accessToken?.access_token
                            ??
                            JSON.parse(localStorage.getItem('access_token') ?? '{}').access_token}`;
                    }
                }
            }
            return config
        }, (error) => Promise.reject(error)
    )

    axiosPrivate.interceptors.response.use(
        response => response,
        async (error) => {
            const prevReq = error?.config;
            if (location.pathname !== '/login') {
                if (error?.response?.status === 401 && !prevReq?.sent) {
                    prevReq.sent = true;
                    const newAccessToken = await refresh(auth ?? JSON.parse(localStorage.getItem('access_token') ?? '{}').access_token);
                    if(newAccessToken && newAccessToken !== undefined){
                        prevReq.headers['authorization'] = `Bearer ${newAccessToken?.data?.access_token}`
                        return axiosPrivate(prevReq).then(
                            setAuth((prevState: AuthObject) => ({ accessToken: newAccessToken, user: prevState.user }))
                        );
                    } else {
                        localStorage.clear();
                        setAuth({ accessToken: {}, user: {} })
                        return
                    }   
                    
                } else if(error?.response.status === 401) {
                    localStorage.removeItem('access_token');
                    setAuth({ accessToken: {}, user: {} });
                    return
                } else if(error?.response.status === 403) {
                    return Promise.reject(error);
                } else if(error?.response.status === 404) {
                    toast(t("server_error"));
                } else if(error?.response.status === 500) {
                    localStorage.removeItem('access_token')
                    setAuth({ accessToken: {}, user: {} })
                    toast(t("server_error"));
                    return
                } else if(error.response.status === 502) {
                    localStorage.removeItem('access_token')
                    setAuth({ accessToken: {}, user: {} })
                    toast(t('server_error'));
                    return
                }
            } else {
                localStorage.removeItem('access_token')    
                return Promise.reject(error);
            }
            
        }
    );

    return axiosPrivate;
}

export default useAxiosPrivate