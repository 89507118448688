import Header from 'components/layout/Header'
import Footer from 'components/layout/Footer'

type Props = {
  children: React.Component | React.ReactElement
}

const Layout = ({ children }: Props) => {

  return (
    <>
      <Header/>

      {children}

      <Footer/>
    </>
  )
}

export default Layout