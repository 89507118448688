import Skeleton from "react-loading-skeleton"

const PhotoWIdgetSkeleton = () => {
    return (
        <>
            <div className="grid-sidebar-header">
                <div className="d-flex align-items-center">
                    <Skeleton baseColor="#191c38" width="90px" height="15px" inline={true} />
                    <Skeleton baseColor="#191c38" width="35px" height="15px" inline={true} />
                </div>
                <div>
                    <a href="/" download="">
                        <svg width='15' height='15'>
                            <use xlinkHref='/theme/font-awesome/solid.svg#download'></use>
                        </svg>
                        Download 2.4MB
                    </a>
                </div>
            </div>
            <div className="grid-sidebar-content">
                <div className="grid-sidebar-content-top">
                    <h4><Skeleton width="30%" height="90%" inline={true} /></h4>
                    <h2><Skeleton baseColor="#254594" width="100%" height="30px" inline={true} /></h2>
                    <Skeleton baseColor="#837868" width="100%" height="385px" inline={true} />
                </div>
                <div className="grid-sidebar-content-middle">
                    <div>
                        <Skeleton width="250px" height="26px" inline={true} count={5} />
                    </div>
                </div>
                <div className="grid-sidebar-content-bottom">
                    <p><Skeleton baseColor="#778bbc" count={5} width="100%" height="100%" /></p>
                </div>
                <div className="grid-sidebar-content-slider">
                    <p>Povezane fotografije</p>
                    <div className="slider-wrapper">
                        <div className="slider-item">
                            <Skeleton baseColor="#242c15" width="100%" height="100px" inline={true} />
                            <p><Skeleton baseColor="#254594" width="80%" height="15px" inline={true} count={2} /></p>
                        </div>
                        <div className="slider-item">
                            <Skeleton baseColor="#242c15" width="100%" height="100px" inline={true} />
                            <p><Skeleton baseColor="#254594" width="80%" height="15px" inline={true} count={2} /></p>
                        </div>
                        <div className="slider-item">
                            <Skeleton baseColor="#242c15" width="100%" height="100px" inline={true} />
                            <p><Skeleton baseColor="#254594" width="80%" height="15px" inline={true} count={2} /></p>
                        </div>
                    </div>
                    <div className="slider-navigation">
                        <a href="/" className="prev">
                            <svg width='15' height='15'>
                                <use xlinkHref='/theme/font-awesome/solid.svg#caret-left'></use>
                            </svg>
                        </a>
                        <a href="/" className="next">
                            <svg width='15' height='15'>
                                <use xlinkHref='/theme/font-awesome/solid.svg#caret-right'></use>
                            </svg>
                        </a>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PhotoWIdgetSkeleton