import FileDto from "services/api/dto/FileDto";

export const emptyFonoDto = {
    uid: "",
    publish_at: "",
    headline: "",
    description: "",
    orig_file_name: "",
    title: "",
    lang: "",
    credit: "",
    source: "",
    copyright: "",
    photographer: "",
    photo_category_code: "",
    photo_categories_codes: [],
    photo_gallery_uid: "",
    review_by_initials: "",
    files: [{
        uid: "",
        label: "",
        name: "",
        mime: "",
        size: 2,
        url: ""
    }]
}

export default interface FonoDto {
    uid: string,
    orig_file_name?: string,
  
    phono_category_code?: string,
    phono_categories_codes?: string[],
    phono_gallery_uid?: string,
  
    lang?: string,
    headline?: string,
    title?: string,
    description?: string,
  
    credit?: string,
    source?: string,
    copyright?: string,
    reporter?: string,
    reporter_initials?: string,
  
    review_by_id?: string,
    review_by_initials?: string,
  
    publish_at?: string,
    publish_date?: string,
  
    geo_location?: string,
  
    emit_at?: string,
  
    files: FileDto[]
}

export function isFonoDto(object: any): object is FonoDto {
    return true;
} 