import FileDto from "services/api/dto/FileDto";

export const emptyVideoDto:VideoDto = {
    uid: "",
    publish_at: "",
    headline: "",
    description: "",
    orig_file_name: "",
    title: "",
    lang: "",
    credit: "",
    source: "",
    copyright: "",
    cameraman: "",
    cameraman_initials: "",
    video_categories_codes: [],
    video_category_code: "",
    video_gallery_uid: "",
    files: [{
        uid: "",
        label: "",
        name: "",
        mime: "",
        size: 2,
        url: ""
    }]
}

export function isVideoDto(object: any): object is VideoDto {
    return true;
} 

export default interface VideoDto {
    uid: string,
    publish_at: string,
    headline?: string,
    description?: string, 
    orig_file_name?: string, 
    title?: string,
    lang?: string,
    credit?: string,
    source?: string,
    copyright?: string,
    cameraman?: string,
    cameraman_initials?: string,
    video_categories_codes?: string[],
    video_category_code?: string,
    video_gallery_uid?: string,
    files: FileDto[]
}