import { formatDateTimeToHumanFromString } from "helpers/formatDateTools";
import { useApiClient } from "contexts/ApiClientContext";
import FonoDto from "services/api/dto/fono/FonoDto";
import { useTranslation } from "react-i18next";
import { useEffect, useRef } from "react";
import { toast } from "react-toastify";
import { saveAs } from 'file-saver';

interface Props {
  fono: FonoDto
}

const fonoItemView = ({ fono }: Props) => {
  const { t } = useTranslation();
  const apiClient = useApiClient();
  const audioRef = useRef<HTMLAudioElement>(null)

  const downloadAudio = async () => {
    try {
        const response = await apiClient.downloadFono({uid: fono.uid, label: 'orig'});
        if(response && response?.data?.url){
            saveAs(response?.data?.url ?? "", fono?.orig_file_name ?? "download_file");
            toast.success(t('download_started'), {pauseOnHover: false, hideProgressBar: true, autoClose: 2000});
        } else {
            toast.error(t('server_error'));
        }
    } catch (errors: any) {
        switch (errors.response.status) {
            case 403:
                toast.error(t('constrained_item'));
                break;
            default:
                break;
        }
    }
  }


  useEffect(() => {
    audioRef.current?.load();
  }, [fono]);

  return (
    <>
      <div className="grid-sidebar-header">
        <div className="d-flex justify-content-center">
          <span>{fono?.publish_at ? formatDateTimeToHumanFromString(fono?.publish_at) : fono?.publish_at}</span>
        </div>
        <div className="download-fono-button">
          <a href="#" onClick={ async () => await downloadAudio() }>
            <svg width="15" height="15">
              <use xlinkHref="/theme/font-awesome/solid.svg#download"></use>
            </svg>
            {t('download')} {Math.round(((fono?.files.find(item => item.label == "orig")?.size ?? 0) / 1024 / 1024) * 100) / 100}MB
          </a>
        </div>
      </div>
      <div className="grid-sidebar-content">
        <div className="grid-sidebar-content-top">
          <h2>{fono?.title}</h2>
          
        </div>
        <audio ref={audioRef} controls style={{width: "100%"}}>
            <source src={fono?.files.find((item) => item.label == 'preview')?.url} type="audio/mpeg"/>
          </audio>
        <div className="grid-sidebar-content-middle">
          {fono?.description}
        </div>
        <div className="grid-sidebar-content-bottom">
          <div className="d-flex justify-content-between">
              <span>{t('source')}:</span>
              <span>{fono?.source}</span>
          </div>
          <div className="d-flex justify-content-between">
              <span>{t('reporter')}:</span>
              <span>{fono?.reporter + (fono?.reporter_initials != '' ?  " (" + fono?.reporter_initials + ")" : "")}</span>
          </div>
          <div className="d-flex justify-content-between">
              <span>{t('language')}:</span>
              <span>{fono?.lang}</span>
          </div>
          <div className="d-flex justify-content-between">
              <span>{t('categories')}:</span>
              <span>{ (fono?.phono_categories_codes ?? []).join(' - ') }</span>
          </div>
        </div>
      </div>
    </>
  );
}

export default fonoItemView