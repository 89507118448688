import styles from 'styles/layout/header.module.scss';
import { Link, useLocation } from 'react-router-dom'
import { useAuth } from "contexts/AuthContext";
import { useTranslation } from 'react-i18next';
import { useEffect, useRef, useState } from 'react'


const Header = () => {
    const { auth, setAuth } = useAuth();
    const [mainMenuToggle, setMainMenuToggle] = useState(false);
    const [menuToggle, setMenuToggle] = useState(false);
    const headerRef = useRef<HTMLDivElement>(null);
    const profileRef = useRef<HTMLUListElement>(null);
    const btnProfileRef = useRef<HTMLButtonElement>(null);
    const { t, i18n } = useTranslation();
    const location = useLocation();

    const onOpenMenu = () => {
        setMenuToggle(!menuToggle)
    }

    const onOpenMainMenu = () => {
        setMainMenuToggle(!mainMenuToggle)
    }

    const logout = () => {
        localStorage.clear();
        setAuth({ accessToken: {}, user: {} })
        window.location.href = '/'
    }

    useEffect(() => {
        function handleClickOutsideHeader(event: MouseEvent): void {
            if (
                headerRef.current &&
                !headerRef.current.contains(event.target as Node)
            ) {
                setMainMenuToggle(false);
            }
        }
        document.addEventListener('mousedown', handleClickOutsideHeader);
        return () => {
            document.removeEventListener('mousedown', handleClickOutsideHeader);
        };
    }, []);

    useEffect(() => {
        function handleClickOutsideProfile(event: MouseEvent): void {
            if (
                profileRef.current &&
                !profileRef.current.contains(event.target as Node) &&
                btnProfileRef.current &&
                !btnProfileRef.current.contains(event.target as Node)
            ) {
                setMenuToggle(false);
            }
        }
        document.addEventListener('mousedown', handleClickOutsideProfile);
        return () => {
            document.removeEventListener('mousedown', handleClickOutsideProfile);
        };
    }, []);

    return (
        <header className="fixed-top" ref={headerRef}>
            <div className="container">
                <nav className="navbar navbar-expand-md navbar-dark">
                    <div className="container">
                        <Link className='navbar-brand' to={'/'}>
                            <img src="/theme/img/logo.png" alt="" style={{ "maxWidth": 105 }} />
                        </Link>

                        <button className="navbar-toggler border-0" type="button" onClick={() => onOpenMainMenu()}>
                            <span></span>
                            <span></span>
                            <span></span>
                        </button>

                        <div className="dropdown">
                            {i18n.language === 'sr'
                                ?
                                <button
                                    className={`btn btn-outline-secondary ${styles.langButton}`}
                                    onClick={() => i18n.changeLanguage('en')}
                                >
                                    EN
                                </button>
                                :
                                <button
                                    className={`btn btn-outline-secondary ${styles.langButton}`}
                                    onClick={() => i18n.changeLanguage('sr')}
                                >
                                    SR
                                </button>
                            }
                            <button ref={btnProfileRef} className="btn dropdown-toggle" type="button" onClick={onOpenMenu}>
                                <span>{auth?.user?.username ?? auth?.user.username}</span>
                                <span>
                                    <svg width='16' height='24'>
                                        <use xlinkHref='/theme/font-awesome/solid.svg#user'></use>
                                    </svg>
                                </span>
                            </button>
                            <ul
                                className={`dropdown-menu ${menuToggle ? 'active-menu' : ''}`}
                                aria-labelledby="dropdownMenuButton1"
                                ref={profileRef}
                            >
                                <li onClick={onOpenMenu}>
                                    <Link to={'/subscription'}>
                                        <span>{t('subscription')}</span>
                                    </Link>
                                </li>
                                <li>
                                    <span style={{ "cursor": "pointer" }} onClick={logout}>
                                        {t('logout')}
                                    </span>
                                </li>
                            </ul>
                        </div>

                        <div className={`collapse navbar-collapse ${mainMenuToggle ? 'show' : ''}`} id="main-menu">
                            <div id="main-menu" className={`${styles.mainMenu}`}>
                                <ul className="navbar-nav text-center">
                                    <li className={`nav-item ${location.pathname.slice(0, 5) === '/news' ? 'active' : ''}`}>
                                        <Link to={'/news'} className="nav-link" onClick={onOpenMainMenu}>
                                            <span>
                                                <svg width='16' height='24'>
                                                    <use xlinkHref='/theme/font-awesome/solid.svg#paper-plane'></use>
                                                </svg>
                                            </span>
                                            <p>{t('articles')}</p>
                                        </Link>
                                    </li>
                                    <li className={`nav-item 
                                        ${location.pathname.slice(0, 7) === '/photos' || location.pathname.slice(0, 16) == '/photo-galleries'
                                            ?
                                            'active'
                                            :
                                            ''}
                                    `}>
                                        <Link to={'/photo-galleries'} className="nav-link" onClick={onOpenMainMenu}>
                                            <span>
                                                <svg width='16' height='24'>
                                                    <use xlinkHref='/theme/font-awesome/solid.svg#camera'></use>
                                                </svg>
                                            </span>
                                            <p>{t('photo')}</p>
                                        </Link>
                                    </li>
                                    <li className={`nav-item ${location.pathname.slice(0, 7) === '/videos' ? 'active' : ''}`}>
                                        <Link to={'/videos'} className="nav-link" onClick={onOpenMainMenu}>
                                            <span>
                                                <svg width='16' height='24'>
                                                    <use xlinkHref='/theme/font-awesome/solid.svg#video'></use>
                                                </svg>
                                            </span>
                                            <p>{t('video')}</p>
                                        </Link>
                                    </li>
                                    <li className={`nav-item ${location.pathname.slice(0, 5) === '/fono' ? 'active' : ''}`}>
                                        <Link to={'/fono'} className="nav-link" onClick={onOpenMainMenu}>
                                            <span>
                                                <svg width='16' height='24'>
                                                    <use xlinkHref='/theme/font-awesome/solid.svg#volume-high'></use>
                                                </svg>
                                            </span>
                                            <p>{t('audio')}</p>
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        {
                            location.pathname.slice(0, 7) === '/photos' || location.pathname.slice(0, 16) == '/photo-galleries'
                                ?
                                <div className={styles.topLinks}>
                                    <Link className={`${styles.topLink} ${location.pathname.slice(0, 16) == '/photo-galleries' ? styles.active : ''}`} to={'/photo-galleries'}>
                                        {t('galleries')}
                                        <img src="/theme/img/gallery.png" alt="gallery" />
                                    </Link>
                                    |
                                    <Link className={`${styles.topLink} ${location.pathname.slice(0, 7) === '/photos' ? styles.active : ''}`} to={'/photos'}>
                                        <img src="/theme/img/single-photo.png" alt="photo" />
                                        {t('all')}
                                    </Link>
                                </div>
                                :
                                null
                        }

                    </div>
                </nav>
            </div>
        </header>
    )
}

export default Header