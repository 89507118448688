import { Routes, Route, Navigate } from 'react-router-dom'
import NewsItemWidget from 'components/news/NewsItemWidget';
import PhotoItemWidget from 'components/photo/PhotoItemWidget';
import VideoItemWidget from 'components/video/VideoItemWidget';
import IndexWrapper from 'components/news/IndexWrapper';
import PhotoWrapper from 'components/photo/PhotoWrapper';
import VideoWrapper from 'components/video/VideoWrapper';
import Subscription from 'pages/Subscription';
import Protected from 'components/Protected';
import Missing from 'pages/Missing';
import Login from 'pages/Login';
import FonoWrapper from 'components/fono/FonoWrapper';
import FonoItemWidget from 'components/fono/FonoItemWidget';
import PhotoGalleriesWrapper from 'components/photoGalleries/PhotoGalleriesWrapper';
import PhotoGalleryItemWidget from 'components/photoGalleries/PhotoGalleryItemWidget';

function Router() {
  return (
    <Routes>
      <Route element={<Protected />}>
        <Route path="/news" element={<IndexWrapper />}>
          <Route path=":uid" element={<NewsItemWidget/>}></Route>
        </Route>
        <Route path="/photos" element={<PhotoWrapper />}>
          <Route path=":uid" element={<PhotoItemWidget />}></Route>
        </Route>
        <Route path="/photo-galleries" element={<PhotoGalleriesWrapper />}>
          <Route path=":uid" element={<PhotoGalleryItemWidget />}>
            <Route path=':photoUid'></Route>
          </Route>
        </Route>
        <Route path="/videos" element={<VideoWrapper />}>
          <Route path=":uid" element={<VideoItemWidget />}>
            <Route path=':videoUid'></Route>
          </Route>
        </Route>
        <Route path="/fono" element={<FonoWrapper />}>
          <Route path=":uid" element={<FonoItemWidget />}></Route>
        </Route>
        <Route path="/subscription" element={<Subscription />} />
        <Route path="/change-password" element={<Missing />} />
      </Route>

      <Route path="/login" element={<Login />} />

      <Route path='/' element={<Navigate to="/news" />} />
      <Route path="*" element={<Missing />} />
    </Routes>
  )
}

export default Router