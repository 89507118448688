import React, { useState, useEffect, Dispatch, SetStateAction } from "react";
import { useApiClient } from "contexts/ApiClientContext";
import { useInfiniteQuery } from "react-query";
import VideoItem from "components/video/VideoItem";
import NewsSkeleton from "components/loaders/NewsLoader";
import LoaderIcon from "components/loaders/NewsSkeleton";
import { Outlet, useParams } from "react-router-dom";
import VideoGalleriesSearchRequestDto from "services/api/dto/videos/VideoGalleriesSearchRequestDto";
import { useInView } from "react-intersection-observer";
import VideoGalleryDto from "services/api/dto/videos/VideoGalleryDto";
import { useTranslation } from "react-i18next";
import forbiddenStyles from 'styles/video/video-forbidden.module.scss'

interface Props {
    searchFilters: VideoGalleriesSearchRequestDto,
    setResultsNumber: Dispatch<SetStateAction<number>> 
}

const Video = ( { searchFilters, setResultsNumber }: Props ) => {
    const { uid } = useParams();
    const { t } = useTranslation();
    const apiClient = useApiClient();
    const { ref, inView } = useInView();
    const [showSideBar, setShowSideBar] = useState<boolean>(uid ? true : false);
    const {
        isLoading,
        data,
        error,
        isFetchingNextPage,
        fetchNextPage,
        hasNextPage,
    } = useInfiniteQuery(['videoGalleries', searchFilters], ({ pageParam }) => apiClient.videoGalleries(pageParam, searchFilters), {
        retry: false,
        getNextPageParam: lastPage => lastPage.meta?.next_page !== undefined && lastPage.meta.next_page > 0 ? lastPage.meta.next_page : false
    });

    useEffect(() => {
        if (!uid) {
            setShowSideBar(false);
        }
        if (inView && hasNextPage) {
            fetchNextPage();
        }
    }, [uid, inView]);

    useEffect(() => {
        setResultsNumber(data?.pages[0].meta?.total_rows ?? 0)
    }, [data]);

    const handleShowSideBar = (): void => {
        if (uid) {
            setShowSideBar(true);
        } else {
            setShowSideBar(!showSideBar);
        }
    };

    return (
        <>
            <section className="grid-layout-video">
                <div className="container">
                    <div className="grid-layout-wrapper">
                        <div className={`grid-item-wrapper ${showSideBar ? 'active' : ''}`}>
                        {
                                (isLoading || error) ? <NewsSkeleton /> :
                                    data?.pages.map((page) =>
                                        <React.Fragment key={page.meta?.next_page}>
                                            <>
                                                {page.data?.map((gallery: VideoGalleryDto) =>
                                                    <VideoItem 
                                                        key={gallery.uid} 
                                                        gallery={gallery} 
                                                        handleShowSideBar={handleShowSideBar}
                                                    />
                                                )}
                                            </>
                                        </React.Fragment>
                                    )
                            }
                        </div>
                        {showSideBar ? <Outlet /> : null}
                    </div>
                    {document.body.clientWidth < 768 && uid 
                    ?
                    ''
                    :
                    <div ref={ref} className="loader">
                        {isFetchingNextPage ? <LoaderIcon /> : (uid ? '' : <span>{t('no_more_results')}</span>)}
                    </div>
                    }
                </div>
                {
                    error ? 
                    <div className={forbiddenStyles.container}>
                        <h1 className={forbiddenStyles.text}>
                            {t('not_subscribe_message')}
                        </h1>
                    </div>
                    :
                    null
                }
            </section>
        </>
    )
}

export default Video;