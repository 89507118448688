import axios, { AxiosError, AxiosInstance } from "axios";
import ApiClientInterface from "services/api/ApiClientInterface";
import ApiResponseDto from "services/api/dto/ApiResponseDto";
import PaginationDto from "services/api/dto/PaginationDto";
import ArticlesSearchRequestDto from "services/api/dto/news/ArticlesSearchRequestDto";
import NewsCategoryDto from "services/api/dto/news/NewsCategoryDto";
import NewsDto from "services/api/dto/news/ArticleDto";
import PhotoDto from "services/api/dto/photos/PhotoDto";
import PhotoCategoryDto from "services/api/dto/photos/PhotoCategoryDto";
import PhotosSearchRequestDto from "services/api/dto/photos/PhotosSearchRequestDto";
import AuthLoginRequestDto from "services/api/dto/auth/AuthLoginRequestDto";
import PhotoGalleriesSearchRequestDto from "services/api/dto/photos/PhotoGalleriesSearchRequestDto";
import PhotoGalleryDto from "services/api/dto/photos/PhotoGalleryDto";
import { AuthLoginResponseDto } from "services/api/dto/auth/AuthLoginResponseDto";
import SubscriptionDto from "services/api/dto/subscription/SubscriptionDto";
import DownloadFileDto from "./dto/DownloadFileDto";
import DownloadFileRequestDto from "./dto/DownloadFileRequestDto";
import VideoGalleriesSearchRequestDto from "./dto/videos/VideoGalleriesSearchRequestDto";
import VideoGalleryDto from "./dto/videos/VideoGalleryDto";
import VideoCategoryDto from "./dto/videos/VideoCategoryDto";
import VideoSearchRequestDto from "./dto/videos/VideoSearchRequestDto";
import VideoDto from "./dto/videos/VideoDto";
import ArticleDto from "services/api/dto/news/ArticleDto";
import FonoCategoryDto from "./dto/fono/FonoCategoryDto";
import FonoSearchRequestDto from "./dto/fono/FonoSearchRequestDto";
import FonoDto from "./dto/fono/FonoDto";
import AccountDto from "./dto/subscription/AccountDto";

export class AxiosApiClient implements ApiClientInterface {
    constructor(
        private httpClient?: AxiosInstance
    ) {

    }

    getHttpClient(): AxiosInstance {
        if (!this.httpClient) {
            throw new Error('Axios http client is not initialized');
        }
        return this.httpClient;
    }

    setHttpClient(httpClient: AxiosInstance): AxiosApiClient {
        this.httpClient = httpClient;
        return this;
    }

    async authLogin({username, password}: AuthLoginRequestDto): 
                                Promise<ApiResponseDto<AuthLoginResponseDto, undefined> | AxiosError | null> 
    {
        const client: AxiosInstance = this.getHttpClient();

        const response =
            await client.post<ApiResponseDto<AuthLoginResponseDto, undefined>>('/auth/login',
                JSON.stringify({ username, password })
            ).catch((err: Error | AxiosError) => {
                if (axios.isAxiosError(err)) {
                    return err;
                }
            });
        
        if (!axios.isAxiosError(response) && response?.data) {
            return response.data;
        } else if(axios.isAxiosError(response)) {
            return response;
        } else {
            return null;
        }
    }

    // public authRefresh = async (refresh_token: string): Promise<any> => { //Promise<ApiResponseDto<AuthLoginResponseDto, undefined>>
    //     return refresh_token;
    // } 

    // public user = async (): Promise<any> => { // Promise<ApiResponseDto<UserDto, undefined>>
    //     return 'hello';
    // }

    public subscription = async (): Promise<ApiResponseDto<SubscriptionDto, undefined>> => {
        const client: AxiosInstance = this.getHttpClient();
        const response = await client.get('/subscription');
        return response?.data;
    }

    public subscriptionAccount = async (): Promise<ApiResponseDto<AccountDto, undefined>> => { //Promise<ApiResponseDto<AccountDto, undefined>>;
        const client: AxiosInstance = this.getHttpClient();
        const response = await client.get('/subscription/account');
        return response?.data;
    }

    // NEWS ARTICLES ----------------------------

    public newsCategories = async (): Promise<ApiResponseDto<NewsCategoryDto[], undefined>> => {
        const client: AxiosInstance = this.getHttpClient();
        const promise = client.get('/news/categories');
        return promise.then((response) => response.data);
    }

    public newsArticles = async (
        pageParam = 1, 
        searchParam: ArticlesSearchRequestDto
    ): Promise<ApiResponseDto<NewsDto[], PaginationDto>> => {
        pageParam = pageParam <= 0 ? 1 : pageParam;
        const client: AxiosInstance = this.getHttpClient();
        const promise = client.get<ApiResponseDto<ArticleDto[], PaginationDto>>('/news/articles', { 
            params: {
                page: pageParam,
                search: searchParam.search !== '' ? searchParam.search : null,
                categories: searchParam.categories,
                publish_at_from: searchParam?.publish_at_from !== '' ? searchParam.publish_at_from : null,
                publish_at_to: searchParam?.publish_at_to !== '' ? searchParam.publish_at_to : null,
            }
        });
        return promise.then((response) => response.data);
    }

    public article = async (articleUid?: string): Promise<ApiResponseDto<ArticleDto, undefined>> => {
        const client: AxiosInstance = this.getHttpClient();
        const promise = client.get(`/news/article/${articleUid}`);
        return promise.then((response) => response.data);
    }

    public downloadNews = async ({uid, label}: DownloadFileRequestDto): Promise<ApiResponseDto<DownloadFileDto, undefined>> => {
        const client: AxiosInstance = this.getHttpClient();
        const promise = client.post('/news/articles/download-file', { uid: uid, label:label });
        return promise.then((response) => response.data);
    }

    // PHOTOS ----------------------------------

    public photoCategories = async (): Promise<ApiResponseDto<PhotoCategoryDto[], undefined>> => {
        const client: AxiosInstance = this.getHttpClient();
        const promise = client.get('/photos/categories');
        return promise.then((response) => response.data);
    }

    public photos = async (
        pageParam = 1, 
        searchParam: PhotosSearchRequestDto
    ): Promise<ApiResponseDto<PhotoDto[], PaginationDto>> => {
        pageParam = pageParam <= 0 ? 1 : pageParam;
        const client: AxiosInstance = this.getHttpClient();
        const promise = client.get<ApiResponseDto<PhotoDto[], PaginationDto>>('/photos/photos', {
            params: {
                page: pageParam,
                search: searchParam.search !== '' ? searchParam.search : null,
                categories: searchParam.categories,
                publish_at_from: searchParam?.publish_at_from !== '' ? searchParam.publish_at_from : null,
                publish_at_to: searchParam?.publish_at_to !== '' ? searchParam.publish_at_to : null,
                credits: 
                    (searchParam.credits && searchParam.credits.length > 0) && searchParam.credits[0] !== ""  ? searchParam.credits : null
            }
        });
        
        return promise.then((response) => response.data);
    }

    public photo = async (photoUid?: string): Promise<ApiResponseDto<PhotoDto, undefined>> => {
        const client: AxiosInstance = this.getHttpClient();
        const promise = client.get(`/photos/photo/${photoUid}`);
        return promise.then((response) => response.data);
    }

    public photoGalleries = async (
        pageParam = 1, 
        searchParam: PhotoGalleriesSearchRequestDto
    ) : Promise<ApiResponseDto<PhotoGalleryDto[], PaginationDto>> => {
        const client: AxiosInstance = this.getHttpClient();
        const promise = client.get(`/photos/galleries`, {
            params: {
                page: pageParam,
                search: searchParam.search !== '' ? searchParam.search : null,
                categories: searchParam.categories,
                publish_at_from: searchParam?.publish_at_from !== '' ? searchParam.publish_at_from : null,
                publish_at_to: searchParam?.publish_at_to !== '' ? searchParam.publish_at_to : null,
            }
        });
        return promise.then((response) => response.data);
    }

    public photoGallery = async (galerryUid?: string): Promise<ApiResponseDto<PhotoGalleryDto, undefined>> => {
        const client: AxiosInstance = this.getHttpClient();
        const promise = client.get(`/photos/gallery/${galerryUid}`);
        return promise.then((response) => response.data);
    }

    public downloadPhoto = async ({uid, label}: DownloadFileRequestDto): Promise<ApiResponseDto<DownloadFileDto, undefined>> => {
        const client: AxiosInstance = this.getHttpClient();
        const promise = client.post('/photos/photos/download-file', { uid: uid, label: label });
        return promise.then((response) => response.data);
    }

    // VIDEOS ----------------------------------

    public videoCategories = async (): Promise<ApiResponseDto<VideoCategoryDto[], undefined>> => {
        const client: AxiosInstance = this.getHttpClient();
        const promise = client.get('/videos/categories');
        return promise.then((response) => response.data);
    }

    public videos = async (
            pageParam = 1, 
            searchParam: VideoSearchRequestDto
        ): Promise<ApiResponseDto<VideoDto[], PaginationDto>> => {
        const client: AxiosInstance = this.getHttpClient();
        const promise = client.get<ApiResponseDto<PhotoDto[], PaginationDto>>('/videos/videos', {
            params: {
                page: pageParam,
                search: searchParam.search !== '' ? searchParam.search : null,
                categories: searchParam.categories,
                publish_at_from: searchParam?.publish_at_from !== '' ? searchParam.publish_at_from : null,
                publish_at_to: searchParam?.publish_at_to !== '' ? searchParam.publish_at_to : null,
                video_gallery_uid: searchParam.video_gallery_uid
            }
        });
        return promise.then((response) => response.data);
    }

    public video = async (galerryUid?: string): Promise<ApiResponseDto<VideoDto, undefined>> => {
        const client: AxiosInstance = this.getHttpClient();
        const promise = client.get(`/photos/gallery/${galerryUid}`);
        return promise.then((response) => response.data);
    }

    public videoGalleries = async (
        pageParam = 1, 
        searchParam: VideoGalleriesSearchRequestDto
    ) : Promise<ApiResponseDto<VideoGalleryDto[], PaginationDto>> => { 
        const client: AxiosInstance = this.getHttpClient();
        const promise = client.get(`/videos/galleries`, {
            params: {
                page: pageParam,
                search: searchParam.search !== '' ? searchParam.search : null,
                categories: searchParam.categories,
                publish_at_from: searchParam?.publish_at_from !== '' ? searchParam.publish_at_from : null,
                publish_at_to: searchParam?.publish_at_to !== '' ? searchParam.publish_at_to : null,
            }
        });
        return promise.then((response) => response.data);
    }

    public videoGallery = async (galerryUid?: string): Promise<ApiResponseDto<VideoGalleryDto, undefined>> => {
        const client: AxiosInstance = this.getHttpClient();
        const promise = client.get(`/videos/gallery/${galerryUid}`);
        return promise.then((response) => response.data);
    }

    public downloadVideo = async ({uid, label}: DownloadFileRequestDto): Promise<ApiResponseDto<DownloadFileDto, undefined>> => {
        const client: AxiosInstance = this.getHttpClient();
        const promise = client.post('/videos/videos/download-file', { uid: uid, label:label });
        return promise.then((response) => response.data);
    }

    // FONO ----------------------------------------
    public fonoCategories = async (): Promise<ApiResponseDto<FonoCategoryDto[], undefined>> => {
        const client: AxiosInstance = this.getHttpClient();
        const promise = client.get('/phonos/categories');
        return promise.then((response) => response.data);
    }

    public fono = async (
        pageParam = 1, 
        searchParam: FonoSearchRequestDto
    ): Promise<ApiResponseDto<FonoDto[], PaginationDto>> => {
        pageParam = pageParam <= 0 ? 1 : pageParam;
        const client: AxiosInstance = this.getHttpClient();
        const promise = client.get<ApiResponseDto<FonoDto[], PaginationDto>>('/phonos/phonos', {
            params: {
                page: pageParam,
                search: searchParam.search !== '' ? searchParam.search : null,
                categories: searchParam.categories,
                publish_at_from: searchParam?.publish_at_from !== '' ? searchParam.publish_at_from : null,
                publish_at_to: searchParam?.publish_at_to !== '' ? searchParam.publish_at_to : null,
            }
        });
        return promise.then((response) => response.data);
    }

    public fonoItem = async (uid?: string): Promise<ApiResponseDto<FonoDto, undefined>> => {
        const client: AxiosInstance = this.getHttpClient();
        const promise = client.get(`/phonos/phono/${uid}`);
        return promise.then((response) => response.data);
    }

    public downloadFono = async ({uid, label}: DownloadFileRequestDto): Promise<ApiResponseDto<DownloadFileDto, undefined>> => {
        const client: AxiosInstance = this.getHttpClient();
        const promise = client.post('/phonos/phonos/download-file', { uid: uid, label:label });
        return promise.then((response) => response.data);
    }

}

export default AxiosApiClient;