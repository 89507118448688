import React, { createContext, useState, useContext } from "react";
import AccessTokenDto from "services/api/dto/auth/AccessTokenDto";
import UserDto from "services/api/dto/auth/UserDto";
import AccountDto from "services/api/dto/subscription/AccountDto";
import SubscriptionDto from "services/api/dto/subscription/SubscriptionDto";

export interface AuthObject {
    user?: UserDto | any,
    accessToken?: AccessTokenDto,
    subscription?: SubscriptionDto
    account?: AccountDto,
}

interface AuthContextProps {
    auth?: AuthObject | null,
    setAuth: React.Dispatch<React.SetStateAction<AuthObject>> | any,
    subscription?: SubscriptionDto,
    account?: AccountDto,
}

const initialCtx = {
    auth: {
        accessToken: JSON.parse(localStorage.getItem('access_token') ?? '{}') ?? null,
        user: JSON.parse(localStorage.getItem('user') ?? '{}') ?? null,
        subscription: JSON.parse(localStorage.getItem('subscription') ?? "{}") ?? null,
        account: JSON.parse(localStorage.getItem('account') ?? "{}") ?? null
    },
    setAuth: (auth: React.SetStateAction<AuthObject>) => auth
}

export const AuthContext = createContext<AuthContextProps>(initialCtx);

interface Props {
    children: any
}

const AuthContextProvider = ({ children }: Props) => {
    const [auth, setAuth] = useState<AuthObject>(
        {
            accessToken: JSON.parse(localStorage.getItem('access_token') ?? '{}') ?? null,
            user: JSON.parse(localStorage.getItem('user') ?? '{}') ?? null,
            subscription: JSON.parse(localStorage.getItem('subscription') ?? "{}") ?? null,
            account: JSON.parse(localStorage.getItem('account') ?? "{}") ?? null
        }
    );

    return (
        <AuthContext.Provider value={{ auth, setAuth }}>
            {children}
        </AuthContext.Provider>
    )
}

export const useAuth = () => {
    return useContext(AuthContext);
};

export default AuthContextProvider