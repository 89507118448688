import { createContext, useCallback, useEffect, useState } from "react";
import CanvasButton from "components/layout/CanvasButton";
import PhotoGalleriesCanvas from "components/photoGalleries/PhotoGalleriesCanvas";
import PhotoGalleriesSearch from "components/photoGalleries/PhotoGalleriesSearch";
import PhotoGallery from "pages/PhotoGallery";
import PhotoGalleriesSearchRequestDto from "services/api/dto/photos/PhotoGalleriesSearchRequestDto";
import { usePageMeta } from "contexts/PageMetaContext";

export const DisplayFilterContext = createContext<{ yOffset: number, show: boolean }>({ yOffset: 0, show: true });

const PhotoGalleriesWrapper = () => {

    const searchHistory = localStorage.getItem('photoGalleriesPage');
    const [search, setSearch] = useState<PhotoGalleriesSearchRequestDto>(JSON.parse(searchHistory ?? "{}") ?? {search: ''});
    const [resultsNumber, setResultsNumber] = useState<number>(0);
    const [displayFilters, setDisplayFilters] = useState<{ yOffset: number, show: boolean }>({ yOffset: 0, show: true });
    const [displayCanvas, setDisplayCanvas] = useState<boolean>(window.innerWidth > 992 ? false : true);

    const { setPageMeta } = usePageMeta();

    useEffect(() => {
        setPageMeta({title: "Foto Galerije" })
    }, [])
    
    const handleScroll = useCallback(() => {
        if (displayFilters.yOffset > window.scrollY && window.innerWidth > 992) {
            setDisplayFilters({ show: true, yOffset: window.scrollY });
        } else {
            setDisplayFilters({ show: false, yOffset: window.scrollY });
        }
    }, [displayFilters]);

    const handleFilters = useCallback(() => {
        if (window.innerWidth > 992) {
            setDisplayCanvas(false);
            setDisplayFilters({ show: true, yOffset: window.scrollY });
        } else {
            setDisplayFilters({ show: false, yOffset: window.scrollY });
            setDisplayCanvas(true);
        }
    }, [displayCanvas]);

    const handleSearchSubmit = (searchObject: PhotoGalleriesSearchRequestDto) => {
        setSearch(searchObject);
    }

    const showCanvas = () => {
        const canvas = document.getElementById("canvas");
        if(canvas != null) {
            canvas.style.width = "100%";
        }  
        // const layout = document.getElementsByClassName('grid-layout-video');
        // if(layout[0] instanceof HTMLDivElement) {
        //     layout[0].style.backgroundColor = "rgba(0,0,0,0.4)";
        // }
    }
  
    const hideCanvas = () => {
        const canvas = document.getElementById("canvas");
        if(canvas != null) {
            canvas.style.width = "0";
        }  
    }

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        }
    }, [handleScroll]);

    useEffect(() => {
        window.addEventListener('resize', handleFilters);
    }, [handleFilters]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [search]);
    
    return(
        <main>
            <DisplayFilterContext.Provider value={displayFilters}>
                { displayCanvas ? <PhotoGalleriesCanvas results={resultsNumber} onSearchSubmit={handleSearchSubmit} hideCanvas={hideCanvas} /> : null }
                {
                    !displayCanvas ? 
                    <PhotoGalleriesSearch results={resultsNumber} onSearchSubmit={handleSearchSubmit} /> : 
                    <CanvasButton showCanvas={showCanvas} />
                }
                <PhotoGallery searchFilters={search} setResultsNumber={setResultsNumber} />
            </DisplayFilterContext.Provider>
        </main>
    )
}

export default PhotoGalleriesWrapper