import { createContext, useEffect } from "react";
import ReactGA from "react-ga4";
import { useAuth } from "./AuthContext";

interface GoogleAnalyticsContextProps {
    ga_measurement_id?: string;
}


export const GoogleAnalyticsContext = createContext<GoogleAnalyticsContextProps>({});

interface Props {
    ga_measurement_id?: string,
    children: any,
}

const GoogleAnalyticsContextProvider = ({ children, ga_measurement_id: initial_ga_measurement_id }: Props) => {

    const ga_measurement_id = initial_ga_measurement_id ?? process.env.REACT_GA_MEASUREMENT_ID ?? 'G-JB4NX9BMN8';

    const { auth } = useAuth();

    useEffect(() => {
        const gaOptions: any = {};
        const gtagOptions: any = {
            'user_properties': {}
        };
        
        if (auth?.user) {
            gtagOptions['user_properties']['subscriber_id'] = auth.user.username;
        };

        if (auth?.account) {
            gtagOptions['user_properties']['subscriber_account'] = auth.account.title;
        }

        ReactGA.initialize(ga_measurement_id, { gaOptions, gtagOptions });
        
    }, [auth]);

    return (
        <GoogleAnalyticsContext.Provider value={{ga_measurement_id}}>
            {children}
        </GoogleAnalyticsContext.Provider>
    )
}

export default GoogleAnalyticsContextProvider