import { formatDateTimeToHumanFromString } from "helpers/formatDateTools";
import { Link, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import PhotoGalleryDto from "services/api/dto/photos/PhotoGalleryDto";

interface Props {
    gallery: PhotoGalleryDto,
    handleShowSideBar(): void,
}

const PhotoGalleryItem = ({ gallery, handleShowSideBar }: Props) => {
    const { uid } = useParams();
    const { t } = useTranslation();

    return (
        <>
        <Link to={`/photo-galleries/${gallery.uid}`} style={{ all: "unset", cursor: "pointer" }}>
            <div className={`layout-item ${uid === gallery.uid ? 'active' : ''}`} onClick={() => handleShowSideBar()}>
                <div className="layout-item-data">
                    <div className="main-img">
                        <figure className="video-figure">
                            <img 
                                className="video-img" style={{width: "100%", height: "100%"}} 
                                src={gallery?.files?.find((item) => item?.label == 'thumb')?.url} 
                                alt={ gallery?.files?.find((item) => item?.label == 'thumb')?.name } 
                            />
                        </figure>
                    </div>
                </div>
                <div className="layout-item-info">
                    <div>
                        <h4>{formatDateTimeToHumanFromString(gallery.publish_at)}</h4>
                        <h2>{ gallery?.title }</h2>
                    </div>
                    <div>
                        <span>{ gallery?.photo_category_code ?? '' }</span>
                    </div>
                </div>
                <div className="layout-item-action">
                    <div className="action-count">
                        { "(" + gallery.items_count + ")" }
                    </div>
                    <svg width='16' height='16' fill="#191c38">
                        <use xlinkHref='/theme/font-awesome/solid.svg#camera'></use>
                    </svg>
                </div>
            </div>
        </Link>
        </>
    )
}

export default PhotoGalleryItem