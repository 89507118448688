const Footer = () => {
    return (
        <footer>
            <div className="container">
                <p className="footer-info">
                    Sva prava zadržana - <a href="index.html">Tanjug</a>© 2015 :: Obilićev venac 2, 11000 Beograd :: tel: <a href="tel:+381 11 3288 284">+381 11 3288 284</a>, <a href="tel:11 3288 285">11 3288 285</a>, <a href="tel:113288 286">113288 286</a>;email: <a href="mailto:office@tanjug.rs">office@tanjug.rs</a>site: <a href="index.html">www.tanjug.rs</a>
                </p>
            </div>
        </footer>
    )
}

export default Footer