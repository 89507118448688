import PhotoDto, { emptyPhotoDto, isPhotoDto } from "services/api/dto/photos/PhotoDto";
import PhotoWidgetSkeleton from "components/loaders/PhotoWIdgetSkeleton";
import styles from "styles/photo/photo-item-view.module.scss";
import { useNavigate, useParams } from "react-router-dom";
import { useApiClient } from "contexts/ApiClientContext";
import { useContext, useEffect, useState } from "react";
import { DisplayFilterContext } from "./PhotoGalleriesWrapper";
import { useQuery } from "react-query";
import PhotoGalleryItemView from "./PhotoGalleryItemView";
import { usePageMeta } from "contexts/PageMetaContext";

const PhotoGalleryItemWidget = () => {
  const { uid } = useParams();
  const navigate = useNavigate();
  const apiClient = useApiClient();
  const displayFilters = useContext(DisplayFilterContext);
  const { isLoading, error, data } = useQuery([uid], () => apiClient.photoGallery(uid), { retry: false });
  const [fetchedItem, setFetchedItem] = useState<PhotoDto>(emptyPhotoDto);
  
  const { setPageMeta } = usePageMeta();

  useEffect(() => {
    if(!isLoading) {
      if (error) {
        console.log('Greska');
      } else if(data?.data && isPhotoDto(data?.data)) {
        setFetchedItem(data?.data);

        setPageMeta({title: "Foto Galerija - " + data.data.title})
      }
    }
  }, [isLoading, error, data]);

  return (
    <div className={ !displayFilters.show && displayFilters.yOffset > 0 ? styles.scrollWrapper : styles.wrapper }>
      <div className={styles.mainWrapper}>
        <span className={styles.close} onClick={() => navigate(`/photo-galleries`)}>
          <svg width='17' height='17'>
            <use xlinkHref='/theme/font-awesome/solid.svg#x'></use>
          </svg>
        </span>

        {!isLoading && isPhotoDto(fetchedItem) ? <PhotoGalleryItemView gallery={fetchedItem} /> : <PhotoWidgetSkeleton />}

      </div>
    </div>
  );
}

export default PhotoGalleryItemWidget