import { formatDateToHumanFromString, formatTimeToHumanFromString } from "helpers/formatDateTools";
import { Link, useParams } from "react-router-dom"
import FonoDto from "services/api/dto/fono/FonoDto";

interface Props {
    fono: FonoDto,
    handleShowSideBar(): void
}

const FonoItem = ({ fono, handleShowSideBar }: Props) => {
    const { uid } = useParams();
    return (
        <>
            <Link to={`/fono/${fono.uid}`} style={{ all: "unset", cursor: "pointer" }}>
                <div className={`layout-item ${uid && (uid === fono.uid) ? 'active' : ''}`} onClick={() => handleShowSideBar()}>
                    <div className="layout-item-data">
                        <span>{ formatDateToHumanFromString(fono?.publish_at ?? "") }</span>
                        <span>{ formatTimeToHumanFromString(fono?.publish_at ?? "")} </span>
                        <p></p>
                        <span>{ fono?.phono_category_code }</span>
                    </div>
                    <div className="layout-item-date">
                        <span>{ formatDateToHumanFromString(fono?.publish_at ?? "")}</span>
                        &nbsp;
                        <span>{ formatTimeToHumanFromString(fono?.publish_at ?? "")} </span>
                    </div>
                    <div className="layout-item-info">
                        <div>
                            <h4>{fono?.headline ?? fono?.phono_category_code}</h4>
                            <h2>{fono.title}</h2>
                        </div>
                        <div>
                            <span>{ fono?.phono_categories_codes?.join(" - ") }</span>
                        </div>
                    </div>
                    <div className="layout-item-action">
                    </div>
                </div>
            </Link>
        </>
    )
}

export default FonoItem