import Skeleton from "react-loading-skeleton"

const NewsWIdgetSkeleton = () => {
    return (
        <>
            <div className="grid-sidebar-header">
                <div className="d-flex align-items-center">
                    <Skeleton baseColor="#191c38" width="90px" height="15px" inline={true} />
                    <Skeleton baseColor="#191c38" width="35px" height="15px" inline={true} />
                </div>
                <div className="d-flex">
                    <a href="/">
                        <svg width="17" height="17">
                            <use xlinkHref="/theme/font-awesome/solid.svg#camera"></use>
                        </svg>
                    </a>
                    <a href="/">
                        <svg width="15" height="15">
                            <use xlinkHref="/theme/font-awesome/solid.svg#download"></use>
                        </svg>
                        Download TXT
                    </a>
                </div>
            </div>
            <div className="grid-sidebar-content">
                <div className="grid-sidebar-content-top">
                    <h4><Skeleton width="30%" height="90%" inline={true} /></h4>
                    <h2><Skeleton baseColor="#254594" width="100%" height="30px" inline={true} count={2} /></h2>
                </div>
                <div className="grid-sidebar-content-middle">
                    <div>
                        <Skeleton width="250px" height="26px" inline={true} count={5} />
                    </div>
                    <a href="/">
                        <svg width="13" height="13">
                            <use xlinkHref="font-awesome/solid.svg#copy"></use>
                        </svg>
                        Copy
                    </a>
                </div>
                <div className="grid-sidebar-content-bottom">
                    <Skeleton baseColor="#778bbc"  count={15} width="100%" height="100%" />
                </div>
            </div>
        </>
    )
}

export default NewsWIdgetSkeleton