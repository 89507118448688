import { formatDateToHumanFromString, formatTimeToHumanFromString } from "helpers/formatDateTools";
import { Link, useParams } from "react-router-dom"
import NewsDto from "services/api/dto/news/ArticleDto"
import styles from "styles/news/news-item.module.scss"

interface Props {
    news: NewsDto,
    handleShowSideBar(): void
}

const NewsItem = ({ news, handleShowSideBar }: Props) => {
    const { uid } = useParams();

    const hasIconComponent = (type: string, text: string) => {
        switch (type) {
            case 'foto':
                return (text.match(/\(.*(foto|photo).*\)$/gmi) ?? []).length;
            case 'video':
                return (text.match(/\(.*video.*\)$/gmi) ?? []).length;
            case 'fono':
                return (text.match(/\(.*(fono|phono|audio).*\)$/gmi) ?? []).length;
            default:
                return false;
        }
    };
    return (
        <>
            <Link to={`/news/${news.uid}`} style={{ all: "unset", cursor: "pointer" }}>
                <div className={`layout-item ${uid && (uid === news.uid) ? 'active' : ''}`} onClick={() => handleShowSideBar()}>
                    <div className="layout-item-data">
                        <span>{ formatDateToHumanFromString(news?.publish_at ?? "") }</span>
                        <span>{ formatTimeToHumanFromString(news?.publish_at ?? "")} </span>
                        <p>{news.embargo ? 'EMBARGO' : ''}</p>
                        <span>{ news?.news_category_code }</span>
                    </div>
                    <div className="layout-item-date">
                        <span>{ formatDateToHumanFromString(news?.publish_at ?? "")}</span>
                        &nbsp;
                        <span>{ formatTimeToHumanFromString(news?.publish_at ?? "")} </span>
                    </div>
                    <div className="layout-item-info">
                        <div>
                            <h4 className={uid ?? styles.description}>{news?.news_category_code + ":" + news?.slug}</h4>
                            <h2>{news.lead}</h2>
                        </div>
                        <div>
                            <span>{ news?.news_categories_codes?.join(" - ") }</span>
                        </div>
                    </div>
                    <div className="layout-item-action">
                        { hasIconComponent('foto', news?.slug ?? '') ? <div className="photo-icon icon">
                            <svg width='16' height='16' fill="#254594">
                                <use xlinkHref='/theme/font-awesome/solid.svg#camera'></use>
                            </svg>
                        </div> : null }
                        { hasIconComponent('video', news?.slug ?? '') ? <div className="video-icon icon">
                            <svg width='16' height='16' fill="#191c38">
                                <use xlinkHref='/theme/font-awesome/solid.svg#video'></use>
                            </svg>
                        </div> : null }
                        { hasIconComponent('fono', news?.slug ?? '') ? <div className="fono-icon icon">
                            <svg width='16' height='16' fill="#da251d">
                                <use xlinkHref='/theme/font-awesome/solid.svg#volume-high'></use>
                            </svg>
                        </div> : null }
                    </div>
                </div>
            </Link>
        </>
    )
}

export default NewsItem