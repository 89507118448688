import JSZip from "jszip"
import { saveAs } from 'file-saver'
import { toast } from "react-toastify"
import { ToastMessages } from "./helperInterfaces"
import DownloadFileDto from "services/api/dto/DownloadFileDto"
import PhotoDto from "services/api/dto/photos/PhotoDto"

/**
 * It takes a URL and returns a promise that resolves to an array of bytes
 * @param {string} url - The URL of the file to download.
 * @returns A promise that resolves to an array of bytes.
 */
 function urlToPromise(url: string): Promise<ArrayBuffer> {
    return new Promise(function (resolve) {
        const oReq = new XMLHttpRequest();
        oReq.open("GET", url, true);
        oReq.responseType = "arraybuffer";

        oReq.onload = function () {
            const arrayBuffer = oReq.response;
            resolve(new Uint8Array(arrayBuffer));
        };

        oReq.send();
    });
}

/**
 * We create a new JSZip object, then we loop through each photo in the array, and add it to the zip
 * file. 
 * 
 * The options are important because we need to tell JSZip that the file is binary. 
 * 
 * @param {DownloadFileDto[]} photos - DownloadFileDto[] - this is the array downloadable files
 */
const downloadAll = (photos: DownloadFileDto[], toastObj: ToastMessages, selectedPhotos: PhotoDto[]): void => {
    const toastId = toast(toastObj.start, { autoClose: false, type: toast.TYPE.INFO, position: "top-left" });
    const zip = new JSZip();
    photos.forEach((photo) => {
        const filename = `${selectedPhotos.find((photoOrig) => photoOrig.uid === photo.uid)?.orig_file_name}`
        zip.file(filename, urlToPromise(photo.url), {binary: true})
    })
    zip.generateAsync({type: 'blob'}).then(function(content){
        toast.update(toastId, {render: toastObj.update, type: toast.TYPE.SUCCESS, autoClose: 3000, position: "top-left" });
        saveAs(content, 'photos.zip');
    })
}

export default downloadAll;