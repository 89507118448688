import { useNavigate, useParams } from "react-router-dom";
import PhotoDto from "services/api/dto/photos/PhotoDto";
import styles from 'styles/photo/grid-layout.module.scss';
import { saveAs } from 'file-saver';
import { formatDateTimeToHumanFromString } from "helpers/formatDateTools";
import { useApiClient } from "contexts/ApiClientContext";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

interface Props {
    photo: PhotoDto,
    handleShowSideBar(): void,
    onSelectPhoto: (photo: PhotoDto) => void,
    checked: boolean
}

const PhotoItemGrid = ({ photo, handleShowSideBar, onSelectPhoto, checked }: Props) => {
    const { uid } = useParams();
    const { t } = useTranslation();
    const apiClient = useApiClient();
    const navigate = useNavigate();

    const downloadPhoto = async () => {
        try {
            const response = await apiClient.downloadPhoto({uid: photo.uid, label: 'orig'});
            if(response && response?.data?.url){
                saveAs(response?.data?.url ?? "", photo?.orig_file_name ?? "download_file");
                toast.success(t('download_started'), {pauseOnHover: false, hideProgressBar: true, autoClose: 2000});
            } else {
                toast.error(t('server_error'));
            }
        } catch (errors: any) {
            switch (errors.response.status) {
                case 403:
                    toast.error(t('constrained_item'));
                    break;
                default:
                    break;
            }
        }
    }

    return (
        <>
            <div className={`${styles.item}${uid === photo.uid ? " " + styles.itemActive : ""}`}>
                <div
                    className={styles.mainImg}
                    onClick={() => {
                        handleShowSideBar();
                        navigate(`/photos/${photo.uid}`);
                    }}
                >
                    <figure>
                        <img src={photo.files.find((item) => item.label == 'thumb')?.url} alt={photo.files.find((item) => item.label == 'thumb')?.name} />
                    </figure>
                </div>
                <div className={styles.info}>
                    <div
                        className={styles.infoData}
                        onClick={() => {
                            handleShowSideBar();
                            navigate(`/photos/${photo.uid}`);
                        }}
                    >
                        <h4>{photo.headline}</h4>
                        <h2>{photo.title}</h2>
                        
                    </div>
                    <div className={styles.action}>
                        <h6>{formatDateTimeToHumanFromString(photo.publish_at)}</h6>
                        <div className={styles.actionSection}>
                            <div className={styles.actionItem}>
                                <svg fill="blue" width='20' height='20' onClick={async () => await downloadPhoto()}>
                                    <use fill="#191c38" xlinkHref='/theme/font-awesome/solid.svg#download'></use>
                                </svg>
                            </div>
                            <div className={styles.actionItem}>
                                <input type="checkbox"
                                    style={{ display: "block" }}
                                    name="photo"
                                    id={photo.uid}
                                    onChange={() => onSelectPhoto(photo)}
                                    checked={checked}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PhotoItemGrid