import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

const NewsSkeleton = () => {

    return (
        <>
            {[...Array(10)].map((el, idx) =>
                <div key={idx} className="layout-item">
                    <div className="layout-item-data">
                        <Skeleton width="40%" height="90%" style={{ "marginBottom": 2 }} inline={true} />
                        <Skeleton width="20%" height="90%" inline={true} />
                        <p><Skeleton width="35%" height="50%" style={{ "marginBottom": 2 }} inline={true} /></p>
                        <Skeleton width="30%" height="90%" inline={true} />
                    </div>
                    <div className="layout-item-info">
                        <div>
                            <h4><Skeleton width="300px" height="90%" inline={true} /></h4>
                            <h2><Skeleton width="100%" height="90%" inline={true} /></h2>
                        </div>
                        <div>
                            <span><Skeleton width="110px" height="90%" inline={true} /></span>
                        </div>
                    </div>
                    <div className="layout-item-action">
                        <a href="/">
                            <svg width='16' height='16'>
                                <use xlinkHref='/theme/font-awesome/solid.svg#camera'></use>
                            </svg>
                        </a>
                        <a href="/">
                            <svg width='16' height='16'>
                                <use xlinkHref='/theme/font-awesome/solid.svg#video'></use>
                            </svg>
                        </a>
                        <a href="/">
                            <svg width='16' height='16'>
                                <use xlinkHref='/theme/font-awesome/solid.svg#play'></use>
                            </svg>
                        </a>
                    </div>
                </div>
            )}
        </>
    )
}

export default NewsSkeleton