import { formatDateToHumanFromString, formatDateToIsoString } from "helpers/formatDateTools";
import FonoSearchRequestDto from "services/api/dto/fono/FonoSearchRequestDto";
import { useContext, useEffect, useReducer, useRef, useState } from "react";
import { DisplayFilterContext } from "components/fono/FonoWrapper";
import styles from "styles/fono/phonos-canvas.module.scss";
import { useApiClient } from "contexts/ApiClientContext";
import { DateRangePicker } from "react-date-range";
import 'react-date-range/dist/theme/default.css';
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import 'react-date-range/dist/styles.css';
import { useQuery } from "react-query";
import Select from 'react-select';
import { parse } from "date-fns";

const initializedDate = formatDateToIsoString(new Date());

const initialState: InternalState = {
    search: "",
    categoryOptions: [],
    selectedCategories: [],
    selectedCategory: undefined,
    publish_at_from: null,
    publish_at_to: null,
    displayRefreshButton: false,
    displayResetButton: false,
    displayCalendars: false,
}

type FormAction =
    { type: "setSearchTerm", payload: string }
    | { type: "setSelectedCategories", payload: Option[] }
    | { type: "setCategoryOptions", payload: Option[] }
    | { type: "setSelectedCategory", payload: Option }
    | { type: "setCalendarValue", payload: { publish_at_from: string, publish_at_to: string } }
    | { type: "displayCalendars", payload: boolean }
    | { type: "displayRefreshButton" | "displayResetButton", payload: boolean }
    | { type: 'setFiltersByHistory', payload: InternalState }
    | { type: 'setInitialState', payload: InternalState }


interface Option {
    value: string,
    label: string,
}

interface InternalState extends FonoSearchRequestDto {
    categoryOptions: Option[],
    selectedCategories: Option[],
    selectedCategory?: Option,
    displayRefreshButton: boolean,
    displayResetButton: boolean,
    displayCalendars: boolean,
}

interface Props {
    onSearchSubmit: (search: FonoSearchRequestDto) => void,
    results: number,
    hideCanvas: () => void
}

function reducer(state: InternalState, action: FormAction): InternalState {
    switch (action.type) {
        case 'setSearchTerm':
            return {
                ...state,
                'search': action.payload,
                'displayRefreshButton': true
            };
        case 'setSelectedCategories':
            return {
                ...state,
                'categories': action.payload.map((item) => item.value),
                'selectedCategories': action.payload,
                'displayRefreshButton': true
            };
        case 'setSelectedCategory':
            return {
                ...state,
                'categories': action.payload.value == "" ? [] : [action.payload.value],
                'selectedCategories': action.payload.value == "" ? [] : [action.payload],
                'selectedCategory': action.payload.value == "" ? undefined : action.payload,
                'displayRefreshButton': true
            }
        case 'displayRefreshButton':
            return {
                ...state,
                'categories': state.selectedCategories.map((item) => item.value),
                'displayResetButton': true,
                'displayRefreshButton': action.payload
            };
        case 'displayCalendars':
            return {
                ...state,
                'displayCalendars': action.payload
            };
        case 'setCalendarValue':
            return {
                ...state,
                'publish_at_from': action.payload.publish_at_from,
                'publish_at_to': action.payload.publish_at_to,
                'displayRefreshButton': true
            };
        case 'setFiltersByHistory':
            return action.payload
        case 'setInitialState':
            return initialState;
        default:
            return state;
    }
}

const emptyOption = {
    label: '',
    value: ''
}

function isInternalState(object: object): object is InternalState {
    return true
}

const FonoCanvas = ( { results, onSearchSubmit, hideCanvas }: Props ) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const apiClient = useApiClient();
    const calendarRef = useRef<HTMLDivElement>(null);
    const displayFilters = useContext(DisplayFilterContext);
    const [state, dispatch] = useReducer(reducer, initialState);
    const [categoryOptions, setCategoryOptions] = useState<Option[]>([]);
    const { data } = useQuery('fonoCategories', () => apiClient.fonoCategories(), { retry: false });
    
    useEffect(() => {
        if (data?.data) {
            if (categoryOptions.length < 1) {
                const optionsArray: Option[] = [];
                data.data.map((category) => {
                    optionsArray.push({ value: category.code, label: category.code + ' - ' + category.title });
                })
                setCategoryOptions(optionsArray);
            }
        }
        const searchHistory = localStorage.getItem('fonoPage');
        if (searchHistory && searchHistory !== null) {
            if (isInternalState(JSON.parse(searchHistory))) {
                dispatch({
                    type: 'setFiltersByHistory',
                    payload: {
                        ...JSON.parse(searchHistory),
                        'displayCalendars': false,
                        'displayRefreshButton': false
                    }
                });
            }
        }
    }, [data]);

    useEffect(() => {
        function handleClickOutside(event: MouseEvent): void {
            if (calendarRef.current && !calendarRef.current.contains(event.target as Node)) {
                dispatch({ type: 'displayCalendars', payload: false });
            }
        }
        // Bind the event listener
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener('mousedown', handleClickOutside);
        };
    });

    const submitForm = ({ search, categories, publish_at_from, publish_at_to }: FonoSearchRequestDto) => {
        dispatch({ type: 'displayRefreshButton', payload: false });
        localStorage.setItem('fonoPage', JSON.stringify(state));
        onSearchSubmit({ search, categories, publish_at_from, publish_at_to });
        navigate('/fono');
    };

    const resetFilters = () => {
        dispatch({ type: 'setInitialState', payload: initialState });
        localStorage.setItem('fonoPage', JSON.stringify(initialState));
        onSearchSubmit({ search: "", categories: [], publish_at_from: "", publish_at_to: "" });
        navigate('/fono');
    };

    return (
        <div className={styles.canvas} tabIndex={-1} id="canvas">
            <div className="d-flex justify-content-end">
                <a onClick={hideCanvas}>
                    <svg width='15' height='15'>
                        <use fill="#fff" xlinkHref='/theme/font-awesome/solid.svg#x'></use>
                    </svg>
                </a>
            </div>
            <div className={styles.filters}>
                <div className="input-group mb-3">
                    <input type="text" className="form-control"
                        placeholder="Pretraga/Search" aria-label="Pretraga/Search"
                        onChange={(e) => dispatch({ type: "setSearchTerm", payload: e.target.value })}
                        onKeyDown={(e) =>
                            e.key === 'Enter'
                                ? submitForm({ 
                                    search: state.search,
                                    categories: state.selectedCategories.map((item) => item.value),
                                    publish_at_from: state.publish_at_from,
                                    publish_at_to: state.publish_at_to
                                })
                                : null
                        }
                        value={state.search}
                    />
                    <button
                        type="button"
                        id="button-addon2"
                        onClick={() => submitForm({
                            search: state.search,
                            categories: state.selectedCategories.map((item) => item.value),
                            publish_at_from: state.publish_at_from,
                            publish_at_to: state.publish_at_to
                        })}
                    >
                        <svg width='19' height='60'>
                            <use fill="#888" xlinkHref='/theme/font-awesome/solid.svg#magnifying-glass'></use>
                        </svg>
                    </button>
                </div>
                <div className={styles.datePicker}>
                    <DateRangePicker
                        showDateDisplay={false}
                        showMonthAndYearPickers={false}
                        staticRanges={[]}
                        inputRanges={[]}
                        ranges={[
                            {
                                startDate: parse(state.publish_at_from ?? initializedDate, "yyyy-MM-dd'T'HH:mm:ssXXX",  new Date(new Date().setHours(0, 0, 0, 0))),
                                endDate: parse(state.publish_at_to ?? initializedDate, "yyyy-MM-dd'T'HH:mm:ssXXX", new Date(new Date().setHours(23, 59, 59, 999))),
                                key: 'selection'
                            }
                        ]}
                        onChange={(e) => {
                            dispatch({ 
                                type: 'setCalendarValue', 
                                payload: { 
                                    publish_at_from: e.selection && typeof(e.selection) != "undefined" ? formatDateToIsoString(new Date((e.selection.startDate ?? new Date()).setHours(0, 0, 0, 0))) : formatDateToIsoString(new Date(new Date().setHours(0, 0, 0, 0))), 
                                    publish_at_to: e.selection && typeof(e.selection) != "undefined" ? formatDateToIsoString(new Date((e.selection.endDate ?? new Date()).setHours(23, 59, 59, 999))) : formatDateToIsoString(new Date(new Date().setHours(23, 59, 59, 999)))
                                }
                            });
                            }
                        }
                        maxDate={new Date()}
                        direction="vertical"
                        className={styles.datePickerContainer}
                    />
                    <span className={styles.dates}>
                        <span>
                            {state.publish_at_from !== null && state.publish_at_from
                                ? formatDateToHumanFromString(state.publish_at_from)
                                : ''
                            }
                        </span>
                        <span>
                            {state.publish_at_to !== null && state.publish_at_to
                                ? formatDateToHumanFromString(state.publish_at_to)
                                : ''
                            }
                        </span>
                    </span>
                </div>
                <div className={styles.categories}>
                    <Select
                        className={styles.item}
                        onChange={(e) => { dispatch({ type: 'setSelectedCategory', payload: e ?? emptyOption }); }}
                        options={categoryOptions}
                        isClearable={true}
                        placeholder={t('select_categories')}
                        value={undefined}
                    />
                </div>
                <div className={styles.buttons}>
                    <button
                        type="reset"
                        className={`btn ${styles.buttonM} ${styles.refreshButton}`}
                        onClick={() => { resetFilters() }}
                    >
                        <svg className={styles.x} width='13' height='13' fill="white">
                            <use xlinkHref='/theme/font-awesome/solid.svg#x'></use>
                        </svg>
                        {t('reset_filters')}
                    </button>
                    <button className={styles.searchButton}
                        type="button"
                        id="button-addon2"
                        onClick={() => submitForm({
                            search: state.search,
                            categories: state.selectedCategories.map((item) => item.value),
                            publish_at_from: state.publish_at_from,
                            publish_at_to: state.publish_at_to
                        })}
                    >
                        <svg width='13' height='13'>
                            <use fill="#fff" xlinkHref='/theme/font-awesome/solid.svg#magnifying-glass'></use>
                        </svg>
                        <span>{t('search_canvas')}</span>
                    </button>
                </div>
            </div>
        </div>
    )
}

export default FonoCanvas