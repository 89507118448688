import { formatDateTimeToHumanFromString } from "helpers/formatDateTools";
import styles from "styles/photo/photo-item-view.module.scss";
import { useApiClient } from "contexts/ApiClientContext";
import PhotoDto, { emptyPhotoDto } from "services/api/dto/photos/PhotoDto";
import ScrollContainer from 'react-indiana-drag-scroll';
import { useTranslation } from "react-i18next";
import { useEffect, useReducer, useRef } from "react";
import { Link, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { saveAs } from 'file-saver';
import Scroller from "components/layout/Scroller";
import PhotoGalleryDto from "services/api/dto/photos/PhotoGalleryDto";
import { usePageMeta } from "contexts/PageMetaContext";

interface Props {
    gallery: PhotoGalleryDto,
}

type Action = {
    type: "setMainPhoto", 
    payload: PhotoDto | undefined
} | { 
    type: "setOtherPhotos", 
    payload: PhotoDto[] 
} | { 
    type: "setPrevPhoto", 
    payload: PhotoDto[] 
} | { 
    type: "setNextPhoto", 
    payload: PhotoDto[] 
} | { 
    type: "setShowNextAndPrev", 
    payload: boolean
}

interface ThisState {
    mainPhoto: PhotoDto | undefined,
    prevPhoto: PhotoDto | undefined,
    nextPhoto: PhotoDto | undefined,
    showNextAndPrev: boolean,
    otherPhotos: PhotoDto[],
}

interface Props {
    gallery: PhotoGalleryDto,
}

const firstState: ThisState = {
    mainPhoto: emptyPhotoDto,
    prevPhoto: emptyPhotoDto,
    nextPhoto: emptyPhotoDto,
    showNextAndPrev: false,
    otherPhotos: []
}

function reducer(state: ThisState, action: Action): ThisState {
    switch (action.type) {
        case 'setMainPhoto':
            return {
                ...state,
                'mainPhoto': action.payload,
            }
        case 'setOtherPhotos':
            return {
                ...state,
                'otherPhotos': action.payload,
            }
        case 'setPrevPhoto':
            return {
                ...state,
                'prevPhoto': 
                            (action.payload.indexOf(state.mainPhoto ?? emptyPhotoDto) != 0) ? 
                            action.payload[action.payload.indexOf(state.mainPhoto ?? emptyPhotoDto) - 1] : 
                            action.payload[action.payload.length - 1],
            }
        case 'setNextPhoto':
            return {
                ...state,
                'nextPhoto': 
                            (action.payload.indexOf(state.mainPhoto ?? emptyPhotoDto) != (action.payload.length - 1)) ? 
                            action.payload[action.payload.indexOf(state.mainPhoto ?? emptyPhotoDto) + 1] : 
                            action.payload[0],
            }
        case 'setShowNextAndPrev':
            return {
                ...state,
                'showNextAndPrev': action.payload,
            }
        default:
            return state
    }
}

const PhotoGalleryItemView = ({ gallery }: Props) => {
    const { t } = useTranslation();
    const apiClient = useApiClient();
    const vieWRef = useRef<HTMLDivElement>(null);
    const [state, dispatch] = useReducer(reducer, firstState);
    const { photoUid } = useParams();

    const { setPageMeta } = usePageMeta();
    
    useEffect(() => {
        if(photoUid == undefined) {
            dispatch({ type: 'setMainPhoto', payload: gallery.photos ? gallery.photos[0] : emptyPhotoDto});
            dispatch({ type: 'setOtherPhotos', payload: gallery?.photos ?? [] });
            dispatch({ type: 'setPrevPhoto', payload: gallery?.photos ?? [] });
            dispatch({ type: 'setNextPhoto', payload: gallery?.photos ?? [] });
        } else {
            const leadUid: string = photoUid;
            const mainPhoto = gallery?.photos?.find((item) => { return item.uid == leadUid});
            
            if (mainPhoto) {
                setPageMeta({title: "Fotografija - (" + mainPhoto.credit + ") - " + (mainPhoto.headline ? mainPhoto.headline + " - " : "") + mainPhoto.title})
            }

            dispatch({ type: 'setMainPhoto', payload: mainPhoto });
            dispatch({ type: 'setOtherPhotos', payload: gallery?.photos ?? [] });
            dispatch({ type: 'setPrevPhoto', payload: gallery?.photos ?? [] });
            dispatch({ type: 'setNextPhoto', payload: gallery?.photos ?? [] });
        }
        vieWRef.current?.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    }, [gallery, photoUid]);

    const downloadPhoto = async () => {
        try {
            const response = await apiClient.downloadPhoto({uid: state.mainPhoto?.uid ?? '', label: 'orig'});
            if(response && response?.data?.url){
                const downloadLink: HTMLAnchorElement = document.createElement('a');
                document.body.append(downloadLink);
                downloadLink.href = response.data.url;
                downloadLink.download = state.mainPhoto?.orig_file_name ?? 'photo_tanjug';
                downloadLink.text = "Fotografija - (" + state.mainPhoto?.credit + ") - " + (state.mainPhoto?.headline ? state.mainPhoto?.headline + " - " : "") + state.mainPhoto?.title;
                
                downloadLink.click();
                downloadLink.remove();
                toast.success(`${t("download_started_single")}`, {autoClose: 1000, position: "top-left"})
            } else {
                toast.error(t('server_error'));
            }
        } catch (errors: any) {
            switch (errors.response.status) {
                case 403:
                    toast.error(t('constrained_item'));
                    break;
                default:
                    break;
            }
        }
    }

    useEffect(() => {
        vieWRef.current?.scrollTo({
            top: 0,
            behavior: 'smooth',
          });
    }, [gallery]);

    return (
        <>
            <div className={styles.header}>
                <div className="d-flex justify-content-center">
                    <span>{state.mainPhoto?.publish_at ? formatDateTimeToHumanFromString(state.mainPhoto?.publish_at) : state.mainPhoto?.publish_at}</span>
                </div>
                <div>
                    <a style={{cursor: "pointer"}} onClick={ async () => await downloadPhoto() }>
                        <svg width='15' height='15'>
                            <use xlinkHref='/theme/font-awesome/solid.svg#download'></use>
                        </svg>
                        {t("download")} {Math.round((state.mainPhoto ? state.mainPhoto.files[0].size / 1024 / 1024 : 0) * 100) / 100}MB
                    </a>
                </div>
            </div>
            <div 
                className={styles.content}
                ref={vieWRef}
                >
                <div className={styles.contentTop}>
                    <figure className="img-placeholder">
                        <img src={ state.mainPhoto?.files.find((item) => item.label == 'watermark')?.url } alt={ state.mainPhoto?.files.find((item) => item.label == 'watermark')?.name } />
                    </figure>
                    <h4>{state.mainPhoto?.headline}</h4>
                    <h2>{state.mainPhoto?.title}</h2>
                </div>
                {state.otherPhotos?.length !== undefined && state.otherPhotos.length > 1
                ?
                <div className={styles.contentSlider}>
                    {state.otherPhotos.length > 3 && document.body.clientWidth > 786
                    ? 
                    <Scroller targetClass={styles.sliderWrapper}/> 
                    : 
                    ''}
                    <p>{t("related_photos")} ({ state.otherPhotos?.length ?? 0 })</p>
                    
                    <ScrollContainer className={styles.sliderWrapper}>
                        { state.otherPhotos?.map((singlePhoto) => 
                            <Link key={singlePhoto?.uid} to={`/photo-galleries/${gallery.uid}/${singlePhoto.uid}`} className={styles.sliderItem}>
                                <div key={singlePhoto?.uid}>
                                    <figure key={singlePhoto?.uid}>
                                        <img key={singlePhoto?.uid} src={ singlePhoto.files.find((item) => item.label == 'thumb')?.url } alt={singlePhoto.orig_file_name} />
                                    </figure>
                                    <p>{singlePhoto?.title}</p>
                                </div>
                            </Link>
                        ) }
                    </ScrollContainer>
                </div>
                :
                ""
                }
                <div className={styles.contentMiddle}>
                    {state.mainPhoto?.description}
                </div>
                <div className={styles.contentBottom}>
                    <div className="d-flex justify-content-between">
                        <span>{t('category')}:</span>
                        <span>{state.mainPhoto?.photo_category_code}</span>
                    </div>
                    <div className="d-flex justify-content-between">
                        <span>{t('credit')}:</span>
                        <span>{state.mainPhoto?.credit}</span>
                    </div>
                    <div className="d-flex justify-content-between">
                        <span>{t('language')}:</span>
                        <span>{state.mainPhoto?.lang}</span>
                    </div>
                    <div className="d-flex justify-content-between">
                        <span>{t('photographer')}:</span>
                        {state.mainPhoto?.photographer !== "null" 
                        ? 
                        <span>{state.mainPhoto?.photographer + (state.mainPhoto?.photographer_initials !== "" ? " (" + state.mainPhoto?.photographer_initials + ")" : "") }</span>
                        : 
                        ""
                        }
                    </div>
                    <div className="d-flex justify-content-between">
                        <span>{t('source')}:</span>
                        <span>{state.mainPhoto?.source}</span>
                    </div>
                    <div className="d-flex justify-content-between">
                        <span>{t('geo_location')}:</span>
                        <span>{state.mainPhoto?.geo_location}</span>
                    </div>
                    <div className="d-flex justify-content-between">
                        <span>{t('copyright')}:</span>
                        <span>{state.mainPhoto?.copyright}</span>
                    </div>
                    <div className="d-flex justify-content-between">
                        <span>{t('categories')}:</span>
                        <span>{ (state.mainPhoto?.photo_categories_codes ?? []).join(' - ') }</span>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PhotoGalleryItemView